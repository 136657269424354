import { createSlice } from '@reduxjs/toolkit';

import { SheetModuleState } from '../../../../contracts/features/sheet-modules.types';
import { NAME } from '../sheetModuleList.constants';

import { createSheetModulesThunk, getSheetModulesThunk } from './sheet-module.actions';
import {
  sheetModulesCreateFulfilledReducer,
  sheetModulesCreateRejectedReducer,
  sheetModulesGetFulfilledReducer,
  sheetModulesGetRejectedReducer,
  teardownReducer,
} from './sheet-module.reducer';

export const initialState: SheetModuleState = {
  sheetModuleList: [],
  newSheetModuleName: '',
  loading: false,
  error: '',
  newSheetModuleError: '',
  count: 0,
};

const sheetModuleSlice = createSlice({
  name: NAME,
  initialState,
  reducers: {
    teardown(state) {
      teardownReducer(state, { type: '' });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSheetModulesThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSheetModulesThunk.fulfilled, sheetModulesGetFulfilledReducer)
      .addCase(getSheetModulesThunk.rejected, sheetModulesGetRejectedReducer);

    builder
      .addCase(createSheetModulesThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(createSheetModulesThunk.fulfilled, sheetModulesCreateFulfilledReducer)
      .addCase(createSheetModulesThunk.rejected, sheetModulesCreateRejectedReducer);
  },
});

export const { teardown } = sheetModuleSlice.actions;

export default sheetModuleSlice.reducer;
