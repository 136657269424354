// Application name
export const NAME = 'statLine';

// Action types

export const ADD_VALUE = `${NAME}/ADD_VALUE`;
export const SUBTRACT_VALUE = `${NAME}/SUBTRACT_VALUE`;
export const SET_MAX_VALUE = `${NAME}/SET_MAX_VALUE`;
export const SET_VALUE_NAME = `${NAME}/SET_VALUE_NAME`;
export const SET_NAME = `${NAME}/SET_NAME`;
export const ADD_STAT = `${NAME}/ADD_STAT`;

export const LOAD_DATA = `${NAME}/LOAD_DATA`;

// layout

export const DEFAULT_STAT = {
  name: '',
  maxValue: 0,
  currentValue: 0,
};

/*
  { 
    name: value
    stats: [
      {
        name: value
        maxValue: number
        currentValue: number
      } 
    ] 
  }
 */
