import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SearchStore } from '../../../../contracts/features/search.types';
import { TagSelect } from '../../../../contracts/features/tags.types';

import { fetchAllCharacters } from './search.actions';
import {
  fetchAllCharactersFulfilledReducer,
  fetchAllCharactersRejectedReducer,
  changePageReducer,
  changeTypeReducer,
  onSearchReducer,
  changeFilterReducer,
  changeSortReducer,
  changeTagReducer,
} from './search.reducer';

export const initialState: SearchStore = {
  characterList: [],
  serverData: [],
  pageData: [],
  characterCount: 0,
  pageCount: 0,
  page: 1,
  filter: 'active',
  type: 'displayName',
  searchText: '',
  tags: [],
  sortBy: 'characterName',
  direction: 'ascending',
  error: '',
};

const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    changePage(state, action: PayloadAction<{ page: number; start: number; end: number }>) {
      changePageReducer(state, action);
    },
    changeType(state, action: PayloadAction<string>) {
      changeTypeReducer(state, action);
    },
    changeFilter(state, action: PayloadAction<string>) {
      changeFilterReducer(state, action);
    },
    onSearch(state, action: PayloadAction<string>) {
      onSearchReducer(state, action);
    },
    changeSort(state, action: PayloadAction<{ sort: string; direction: string }>) {
      changeSortReducer(state, action);
    },
    changeTag(state, action: PayloadAction<{ tags: TagSelect[]; type: boolean }>) {
      changeTagReducer(state, action);
    },
    resetSearch(state) {
      state.characterList = [];
      state.serverData = [];
      state.pageData = [];
      state.characterCount = 0;
      state.pageCount = 0;
      state.page = 1;
      state.filter = 'active';
      state.type = 'displayName';
      state.searchText = '';
      state.tags = [];
      state.sortBy = 'characterName';
      state.direction = 'ascending';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllCharacters.fulfilled, fetchAllCharactersFulfilledReducer)
      .addCase(fetchAllCharacters.rejected, fetchAllCharactersRejectedReducer);
  },
});

export const { changePage, changeType, onSearch, changeFilter, changeSort, changeTag, resetSearch } =
  searchSlice.actions;

export default searchSlice.reducer;
