import { CaseReducer, PayloadAction, SerializedError } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import _set from 'lodash/set';

import {
  SheetModuleBuilderState,
  SheetModuleResponse,
} from '../../../../contracts/features/sheet-module-builder.types';
import {
  calculateDifference,
  calculateMergedCellData,
  calculateUnmergedCellData,
  validateCellId,
} from '../helpers/grid-layout-helpers';
import landscapeGridLayout, { defaultCell } from '../constants/landscape-grid-layout';
import { ICellData } from '../types';
import { getToken, setToken } from '../../../services/localStorage';

import { defaultModalData } from './sheet-module-builder.slice';

export const getSheetModuleFulfilledReducer: CaseReducer<
  SheetModuleBuilderState,
  PayloadAction<SheetModuleResponse, string, { arg: string; requestId: string; requestStatus: 'fulfilled' }, never>
> = (state, action) => {
  const { payload } = action;
  const { id, name, config = { customVariables: [], layout: landscapeGridLayout } } = payload;
  const { layout, customVariables } = config || { customVariables: [], layout: landscapeGridLayout };

  state.gridLayout = layout || landscapeGridLayout;
  state.customVariables = customVariables || [];
  state.id = id;
  state.name = name;
  state.isLoading = false;
};

export const getSheetModuleRejectedReducer: CaseReducer<
  SheetModuleBuilderState,
  PayloadAction<unknown, string, unknown, SerializedError>
> = (_state, action) => {
  const { error } = action;
  console.error('getSheetModuleRejectedReducer - error', error.message);
  toast.error('Failed to fetch sheet module');
};

export const saveSheetModuleFulfilledReducer: CaseReducer<
  SheetModuleBuilderState,
  PayloadAction<SheetModuleResponse, string, unknown, never>
> = () => {
  toast.success('Sheet Module Saved');
};

export const saveSheetModuleRejectedReducer: CaseReducer<
  SheetModuleBuilderState,
  PayloadAction<unknown, string, unknown, SerializedError>
> = (_state, action) => {
  const { error } = action;
  console.error('saveSheetModuleRejectedReducer - error', error.message);
  toast.error('Failed to save sheet module');
};

export const updateCellDataReducer: CaseReducer<SheetModuleBuilderState, PayloadAction<ICellData>> = (
  state,
  action
) => {
  const cellData = state.modal.cellData;

  const updatedCellData = { ...cellData, ...action.payload };

  state.modal.cellData = updatedCellData;
};

export const saveCellDataReducer: CaseReducer<SheetModuleBuilderState> = (state) => {
  const cellData = state.modal.cellData;
  const position = state.modal.position;
  const gridLayout = state.gridLayout;

  if (validateCellId({ grid: gridLayout, cellId: cellData.cellId, position })) {
    toast.error('Failed to Update Cell, Cell ID already in use');
  } else {
    // find row index
    const rowId = gridLayout.findIndex((rowData) => rowData.rowPosition === position.y + 1);
    const rowData = gridLayout[rowId].columns;
    // find column index
    const columnPosition = cellData.columnPosition;
    const columnId = rowData.findIndex((columnData) => columnData.columnPosition === columnPosition);

    state.gridLayout = _set(gridLayout, [rowId, 'columns', columnId], cellData);

    state.modal = defaultModalData;
  }
};

export const mergeCellsReducer: CaseReducer<
  SheetModuleBuilderState,
  PayloadAction<{ row: number; startColumn: number; endColumn: number }>
> = (state, action) => {
  const { row, startColumn, endColumn } = action.payload;
  const gridLayout = JSON.parse(JSON.stringify(state.gridLayout));

  const { difference, startIndex } = calculateDifference({ row, gridLayout, startColumn, endColumn });
  const updatedFormatData = calculateMergedCellData({ gridLayout, startColumn: startIndex, difference, row });

  state.gridLayout = updatedFormatData;
};

export const unmergeCellReducer: CaseReducer<SheetModuleBuilderState> = (state) => {
  const gridLayout = JSON.parse(JSON.stringify(state.gridLayout));

  const cellData = state.modal.cellData;
  const position = state.modal.position;
  const updatedFormatData = calculateUnmergedCellData({ gridLayout, cellData, position });

  state.gridLayout = updatedFormatData;
};

export const clearCellDataReducer: CaseReducer<SheetModuleBuilderState> = (state) => {
  const gridLayout = state.gridLayout;
  const cellData = state.modal.cellData;
  const position = state.modal.position;
  // find row index
  const rowId = gridLayout.findIndex((rowData) => rowData.rowPosition === position.y + 1);
  const rowData = gridLayout[rowId].columns;
  // find column index

  const columnPosition = cellData.columnPosition;
  const columnId = rowData.findIndex((columnData) => columnData.columnPosition === columnPosition);

  state.gridLayout = _set(gridLayout, [rowId, 'columns', columnId], defaultCell);

  state.modal = defaultModalData;
};

export const copyCellDataReducer: CaseReducer<SheetModuleBuilderState> = (state) => {
  const { checked, style, type, value } = state.modal.cellData;

  const copyData = { checked, style, type, value };

  setToken({ key: 'clipboard', data: copyData });
};

export const pasteCellDataReducer: CaseReducer<SheetModuleBuilderState> = (state) => {
  const pasteData = getToken('clipboard');

  state.modal.cellData.checked = pasteData.checked;
  state.modal.cellData.style = pasteData.style;
  state.modal.cellData.type = pasteData.type;
  state.modal.cellData.value = pasteData.value;
};

export const openModalReducer: CaseReducer<SheetModuleBuilderState, PayloadAction<{ row: number; column: number }>> = (
  state,
  action
) => {
  const { row, column } = action.payload;

  const gridLayout = state.gridLayout;

  const rowId = gridLayout.findIndex((rowData) => rowData.rowPosition === row);
  const rowData = gridLayout[rowId].columns;
  const columnId = rowData.findIndex((columnData) => columnData.columnPosition === column);
  const cellData = rowData[columnId];

  state.modal = {
    ...defaultModalData,
    isOpen: true,
    position: { x: columnId, y: rowId },
    cellData,
  };
};

export const closeModalReducer: CaseReducer<SheetModuleBuilderState> = (state) => {
  state.modal = defaultModalData;
};

export const addCustomVariableReducer: CaseReducer<SheetModuleBuilderState, PayloadAction<string>> = (
  state,
  action
) => {
  const { payload } = action;

  state.customVariables.push(payload);
};

export const removeCustomVariableReducer: CaseReducer<SheetModuleBuilderState, PayloadAction<string>> = (
  state,
  action
) => {
  const { payload } = action;

  const currentList = state.customVariables;

  const updatedList = currentList.reduce((acc, variable) => {
    if (variable === payload) {
      return acc;
    }

    return [...acc, variable];
  }, [] as string[]);

  state.customVariables = updatedList;
};
