import { createSlice } from '@reduxjs/toolkit';

import { AuthenticationConfig } from '../../../../contracts/features/authentication.types';

import {
  loggedOutReducer,
  loginUserFulfilledReducer,
  loginUserRejectedReducer,
  authenticationCheckFulfilledReducer,
  authenticationCheckRejectedReducer,
  initialAuthenticationCheckFulfilledReducer,
  initialAuthenticationCheckRejectedReducer,
  resetUserPasswordFulfilledReducer,
  resetUserPasswordRejectedReducer,
  updateUserDetailsReducer,
  updateUserFulfilledReducer,
  updateUserRejectedReducer,
} from './authentication.reducer';
import {
  loginUser,
  authenticationCheck,
  initialAuthenticationCheck,
  logoutUser,
  resetUserPassword,
  updateUserAction,
} from './authentication.actions';

export const initialState: AuthenticationConfig = {
  loggedIn: false,
  error: '',
  initialAuthCheck: false,
  user: {
    email: '',
    username: '',
    firstName: '',
    lastName: '',
    firstTime: false,
    id: '',
  },
  userDetails: {
    email: '',
    username: '',
    firstName: '',
    lastName: '',
    firstTime: false,
    id: '',
  },
  roles: [],
  message: '',
  signIn: '',
};

const authenticationSlice = createSlice({
  name: 'authentication',
  initialState,
  reducers: {
    updateUserDetails(state, action) {
      updateUserDetailsReducer(state, action);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.fulfilled, loginUserFulfilledReducer)
      .addCase(loginUser.rejected, loginUserRejectedReducer);
    builder
      .addCase(authenticationCheck.fulfilled, authenticationCheckFulfilledReducer)
      .addCase(authenticationCheck.rejected, authenticationCheckRejectedReducer);
    builder
      .addCase(initialAuthenticationCheck.fulfilled, initialAuthenticationCheckFulfilledReducer)
      .addCase(initialAuthenticationCheck.rejected, initialAuthenticationCheckRejectedReducer);
    builder.addCase(logoutUser.fulfilled, loggedOutReducer);

    builder
      .addCase(resetUserPassword.fulfilled, resetUserPasswordFulfilledReducer)
      .addCase(resetUserPassword.rejected, resetUserPasswordRejectedReducer);
    builder
      .addCase(updateUserAction.fulfilled, updateUserFulfilledReducer)
      .addCase(updateUserAction.rejected, updateUserRejectedReducer);
  },
});

export const { updateUserDetails } = authenticationSlice.actions;

export default authenticationSlice.reducer;
