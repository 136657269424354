import React from 'react';

import * as CSS from './loading-spinner.styles';

const LoadingSpinner = (): JSX.Element => (
  <CSS.LoadingWrapper>
    <CSS.Loader />
  </CSS.LoadingWrapper>
);

export default LoadingSpinner;
