import { RootState } from '../../../store/store';
import { SelectedSheet, SheetOrder } from '../../../../contracts/character.types';
import { GlobalVariables } from '../../../../contracts/sheet-data';
import { CharacterSheet, CustomGlobals } from '../../../../contracts/features/character-editor.types';

export const getDetails = (state: RootState): CharacterSheet => state.characterEditor.characterSheet;

export const getId = (state: RootState): string => state.characterEditor.characterSheet.id;
export const getCreatedNewCharacter = (state: RootState): boolean => state.characterEditor.createdNewCharacter;

export const getSheetOrder = (state: RootState): Array<SheetOrder> =>
  state.characterEditor?.characterSheet?.sheetOrder || [];

export const getSheetSelected = (state: RootState): SelectedSheet => state.characterEditor.options.selected;

export const getCustomGlobalVariables = (state: RootState): CustomGlobals =>
  state.characterEditor.characterSheet.customGlobals;

export const getFormIsHalfFilledOut = (state: RootState): boolean => state.characterEditor.formIsHalfFilledOut;

export const getGlobalVariables = (state: RootState): GlobalVariables => {
  const { displayName, customPlayerName, unspentXp, characterName } = state.characterEditor.characterSheet;

  return {
    displayName,
    customPlayerName,
    unspentXp,
    characterName,
  };
};

export const getNotes = (state: RootState): string => state.characterEditor.characterSheet.notes;

export const getDynamicTags = (state: RootState): string[] => state.characterEditor.characterSheet.dynamicTags || [];
