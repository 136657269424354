import { ThunkAction } from 'redux-thunk';
import { AnyAction } from 'redux';
import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  getTagsBySystem,
  createNewTag,
  getTagsBySystemDetailed,
  getTagById,
  updateTagById,
  deleteTagById,
} from '../../../services/tag';
import { RootState } from '../../../store/store';
import { TagPayload } from '../../../../contracts/features/tags.types';

export const fetchTagsThunk = createAsyncThunk('tags/fetch-tags', () => {
  return getTagsBySystem();
});

export const getTags =
  (): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch): Promise<void> => {
    await dispatch(fetchTagsThunk());
  };

export const fetchTagsDetailedThunk = createAsyncThunk('tags/fetch-tags-detailed', () => {
  return getTagsBySystemDetailed();
});

export const getTagsDetailed =
  (): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch): Promise<void> => {
    await dispatch(fetchTagsDetailedThunk());
  };

export const newTagThunk = createAsyncThunk('tags/create-tag', (tag: string) => {
  return createNewTag(tag);
});

export const newTag =
  (tag: string): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch): Promise<void> => {
    await dispatch(newTagThunk(tag));
    await dispatch(fetchTagsDetailedThunk());
  };

export const fetchTagThunk = createAsyncThunk('tags/fetch-tag', (tag: string) => {
  return getTagById(tag);
});

export const fetchTag =
  (tag: string): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch): Promise<void> => {
    await dispatch(fetchTagThunk(tag));
  };

export const updateTagThunk = createAsyncThunk('tags/update-tag', (tag: TagPayload) => {
  return updateTagById(tag);
});

export const updateTag =
  (): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch, getState): Promise<void> => {
    const state = getState();
    const tag = state.tags.tag;

    await dispatch(updateTagThunk(tag));
  };

export const deleteTagThunk = createAsyncThunk('tags/delete-tag', (tag: string) => {
  return deleteTagById(tag);
});

export const deleteTag =
  (tag: string): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch): Promise<void> => {
    await dispatch(deleteTagThunk(tag));
    await dispatch(fetchTagsThunk());
  };
