import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkAction } from 'redux-thunk';
import { AnyAction } from 'redux';
import { toast } from 'react-toastify';

import { getAll, create, getById, save } from '../../../services/role';
import { RootState } from '../../../store/store';
import { RoleEdit } from '../../../../contracts/features/roles.types';

export const fetchAllRoles = createAsyncThunk('roles/fetch-all-roles', () => {
  return getAll();
});
export const fetchSingleRole = createAsyncThunk('roles/fetch-role', (id: string) => {
  return getById(id);
});

export const createRole = createAsyncThunk('roles/create-new-role', (roleName: string) => {
  return create(roleName);
});

export const updateRole = createAsyncThunk(
  'roles/update-role',
  ({ roleId, roleData }: { roleId: string; roleData: RoleEdit }) => {
    return save(roleId, roleData);
  }
);

export const getAllRoles =
  (): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch): Promise<void> => {
    await dispatch(fetchAllRoles());
  };
export const getRole =
  (id: string): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch): Promise<void> => {
    await dispatch(fetchSingleRole(id));
  };

export const roleCreator =
  (roleName: string): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch): Promise<void> => {
    await dispatch(createRole(roleName));
  };

export const saveRole =
  (roleId: string): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch, getState): Promise<void> => {
    const state = getState();

    const roleData = state.roles.roleEdit;

    if (!roleData.name) {
      toast.error('Update Role Failure - name cannot be blank');
      return;
    }

    await dispatch(updateRole({ roleId, roleData }));
  };
