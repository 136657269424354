import { CaseReducer, PayloadAction, SerializedError } from '@reduxjs/toolkit';

import { SearchCharacter, SearchStore } from '../../../../contracts/features/search.types';
import * as types from '../search.constants';
import { TagSelect } from '../../../../contracts/features/tags.types';

import { processCharacters, processCharactersWithFilter } from './utils/process-characters';
import { calculatePagesCount, filterData, searchData, sortCharacters, filterByTag } from './utils/sorter';

const dataStart = 0;
const dataEnd = types.CHARACTERS_PER_PAGE;

export const fetchAllCharactersFulfilledReducer: CaseReducer<SearchStore, PayloadAction<SearchCharacter[]>> = (
  state,
  action
) => {
  const { payload } = action;

  const { characterList, characterCount, pageCount, page, pageData, serverData } = processCharacters(state, payload);

  state.characterList = characterList;
  state.characterCount = characterCount;
  state.pageCount = pageCount;
  state.page = page;
  state.pageData = pageData;
  state.serverData = serverData;
};

export const fetchAllCharactersRejectedReducer: CaseReducer<
  SearchStore,
  PayloadAction<unknown, string, unknown, SerializedError>
> = (state, action) => {
  const { error } = action;
  state.error = error.message || 'fetch all character error';
  console.log('fetchAllCharactersRejectedReducer - error', error.message);
};

export const changePageReducer: CaseReducer<
  SearchStore,
  PayloadAction<{ page: number; start: number; end: number }>
> = (state, action) => {
  const { page, end, start } = action.payload;

  const { characterList } = state;

  const pageData = characterList.slice(start, end);

  state.page = page;
  state.pageData = pageData;
};

export const changeTypeReducer: CaseReducer<SearchStore, PayloadAction<string>> = (state, action) => {
  const type = action.payload;

  const { characterList, characterCount, pageCount, page, pageData } = processCharacters(state, state.serverData);

  state.characterList = characterList;
  state.characterCount = characterCount;
  state.pageCount = pageCount;
  state.page = page;
  state.pageData = pageData;
  state.type = type;
  state.searchText = '';
  state.tags = [];
};

export const changeFilterReducer: CaseReducer<SearchStore, PayloadAction<string>> = (state, action) => {
  const filter = action.payload;

  const { characterList, characterCount, pageCount, page, pageData } = processCharactersWithFilter(
    state,
    state.serverData,
    filter
  );

  state.characterList = characterList;
  state.characterCount = characterCount;
  state.pageCount = pageCount;
  state.page = page;
  state.pageData = pageData;
  state.filter = filter;
  state.searchText = '';
  state.tags = [];
};

export const onSearchReducer: CaseReducer<SearchStore, PayloadAction<string>> = (state, action) => {
  const search = action.payload;
  const { serverData, filter, type, sortBy, direction } = state;

  const data = searchData(search, type, serverData);
  const characterList = filterData(filter, data);
  const sortedCharacterList = sortCharacters(characterList, sortBy, direction);

  const pageData = sortedCharacterList.slice(dataStart, dataEnd);
  const characterCount = characterList.length;

  const pageCount = calculatePagesCount(characterCount);

  state.searchText = search;
  state.characterList = sortedCharacterList;
  state.pageData = pageData;
  state.characterCount = characterCount;
  state.pageCount = pageCount;
  state.page = 1;
};

export const changeSortReducer: CaseReducer<SearchStore, PayloadAction<{ sort: string; direction: string }>> = (
  state,
  action
) => {
  const { sort, direction } = action.payload;

  const { serverData, filter } = state;

  const characterList = filterData(filter, serverData);

  const sortedCharacterList = sortCharacters(characterList, sort, direction);
  const pageData = sortedCharacterList.slice(dataStart, dataEnd);

  state.characterList = sortedCharacterList;
  state.pageData = pageData;
  state.sortBy = sort;
  state.direction = direction;
  state.page = 1;
};

export const changeTagReducer: CaseReducer<SearchStore, PayloadAction<{ tags: TagSelect[]; type: boolean }>> = (
  state,
  action
): void => {
  const { tags, type } = action.payload;

  const { serverData, filter, sortBy, direction } = state;

  const data = filterData(filter, serverData);
  const characterList = filterByTag(tags, data, type);
  const characterCount = characterList.length;
  const pageCount = calculatePagesCount(characterCount);

  const sortedCharacterList = sortCharacters(characterList, sortBy, direction);
  const pageData = sortedCharacterList.slice(dataStart, dataEnd);

  state.characterList = sortedCharacterList;
  state.pageData = pageData;
  state.characterCount = characterCount;
  state.pageCount = pageCount;
  state.page = 1;
  state.tags = tags;
};
