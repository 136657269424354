import axios from 'axios';

import { baseUrl } from '../constants/index';

const system = 'seaxe';

const url = `${baseUrl}/${system}/api/v2/characterSheets/user/`;

export const getCharacters = (_id) => axios.get(`${url}${_id}`).then((response) => response.data);

export default { getCharacters };
