import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';

import { UserState } from '../../../../contracts/features/user.types';

export const resetUserReducer: CaseReducer<UserState> = (state) => {
  state.firstName = '';
  state.lastName = '';
  state.email = '';
  state.message = '';
  state.isLoading = false;
  state.newUser = { _id: '', displayName: '' };
};

export const setFirstNameReducer: CaseReducer<UserState, PayloadAction<string>> = (state, action) => {
  const { payload } = action;

  state.firstName = payload;
};

export const setLastNameReducer: CaseReducer<UserState, PayloadAction<string>> = (state, action) => {
  const { payload } = action;

  state.lastName = payload;
};

export const setEmailReducer: CaseReducer<UserState, PayloadAction<string>> = (state, action) => {
  const { payload } = action;

  state.email = payload;
};

export const createUserPendingReducer: CaseReducer<UserState> = (state) => {
  state.isLoading = true;
};
export const createUserFulfilledReducer: CaseReducer<UserState> = (state) => {
  state.isLoading = false;
  state.firstName = '';
  state.lastName = '';
  state.email = '';
  state.message = 'User Created Successfully, select new user from dropdown';
};
export const createUserRejectedReducer: CaseReducer<UserState> = (state, action) => {
  const { error } = action;
  state.isLoading = false;
  state.message = 'Error: Server Error or User with Email already exists. Check logs.';
  console.log('Error: create user', error.message);
};
