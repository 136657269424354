// Application name
export const NAME = 'characterEditor';

// Action types

export const GET_CHARACTER = `${NAME}/GET_CHARACTER`;
export const CREATE_CHARACTER = `${NAME}/CREATE_CHARACTER`;
export const SAVE_CHARACTER = `${NAME}/SAVE_CHARACTER`;
export const DELETE_CHARACTER = `${NAME}/DELETE_CHARACTER`;

// values

export const defaultSheets = ['65953645-17b0-4542-abc6-3814faa379e2', 'f7a0a924-1a0d-4a69-86e2-d0403c84a786'];

export const MY_CHARACTERS = 'MY_CHARACTERS';
export const SEARCH_CHARACTERS = 'SEARCH_CHARACTERS';
