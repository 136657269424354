import { CaseReducer, PayloadAction, SerializedError } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import { SearchCharacter } from '../../../../contracts/features/search.types';
import { UserDetails, UsersState } from '../../../../contracts/features/users.types';

export const fetchListOfUsersFulfilledReducer: CaseReducer<UsersState, PayloadAction<SearchCharacter[]>> = (
  state,
  action
) => {
  const { payload } = action;

  state.list = payload;
};

export const fetchListOfUsersRejectedReducer: CaseReducer<
  UsersState,
  PayloadAction<unknown, string, unknown, SerializedError>
> = (_state, action) => {
  const { error } = action;
  console.log('fetchListOfUsersRejectedReducer - error', error.message);
};

export const resetUsersReducer: CaseReducer<UsersState> = (state) => {
  state.id = '';
  state.username = '';
  state.email = '';
  state.firstName = '';
  state.lastName = '';
  state.roles = [];
  state.list = [];
};

export const fetchUserByIdFulfilledReducer: CaseReducer<UsersState, PayloadAction<UserDetails>> = (state, action) => {
  const { payload } = action;

  state.id = payload.id;
  state.username = payload.username;
  state.email = payload.email;
  state.firstName = payload.firstName;
  state.lastName = payload.lastName;
  state.roles = payload.roles;
};

export const fetchUserByIdRejectedReducer: CaseReducer<
  UsersState,
  PayloadAction<unknown, string, unknown, SerializedError>
> = (_state, action) => {
  const { error } = action;
  console.log('fetchListOfUsersRejectedReducer - error', error.message);
};

export const updateUserDetailsReducer: CaseReducer<UsersState, PayloadAction<Partial<UserDetails>>> = (
  state,
  action
) => {
  const { payload } = action;

  state.id = payload?.id || state.id;
  state.username = payload?.username || state.username;
  state.email = payload?.email || state.email;
  state.firstName = payload?.firstName || state.firstName;
  state.lastName = payload?.lastName || state.lastName;
  state.roles = payload?.roles || state.roles;
};

export const updateSingleUserFulfilledReducer: CaseReducer<UsersState, PayloadAction<string, string, unknown>> = () => {
  toast.success('Update User Successful');
};

export const updateSingleUserRejectedReducer: CaseReducer<
  UsersState,
  PayloadAction<unknown, string, unknown, SerializedError>
> = () => {
  toast.error('Update User failed');
};

export const deleteSingleUserFulfilledReducer: CaseReducer<UsersState, PayloadAction<string, string, unknown>> = (
  state
) => {
  toast.success('Delete User Successful');

  state.id = '';
  state.username = '';
  state.email = '';
  state.firstName = '';
  state.lastName = '';
  state.roles = [];
};

export const deleteSingleUserRejectedReducer: CaseReducer<
  UsersState,
  PayloadAction<unknown, string, unknown, SerializedError>
> = () => {
  toast.error('Update User failed');
};
