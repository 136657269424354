const { localStorage } = window;

export const getToken = (key) => {
  const token = localStorage[key] || '{}';
  return JSON.parse(token) || {};
};

export const setToken = ({ data, key }) => {
  localStorage.setItem(key, JSON.stringify(data));
};
