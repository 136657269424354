const defaultTitle = 'NCrow Character Sheet DB';

export const routerChangeAction = '@@router/LOCATION_CHANGE';
export const baseUrl = '';

export const setDocumentTitle = newTitle => {
  if (document.title !== newTitle) {
    document.title = newTitle;
  }
};

export const resetDocumentTitle = () => {
  if (document.title !== defaultTitle) {
    document.title = defaultTitle;
  }
};
