import { CharacterHours } from '../../../../contracts/experience.types';

export const values = {
  base: 50,
  setup: 25,
  takedown: 25,
  ref: 25,
  extra: 0,
  monster: 0,
};

export const format = {
  setup: 'checkbox',
  takedown: 'checkbox',
  ref: 'checkbox',
  extra: 'number',
  monster: 'number',
} as const;

export const characterConfig = {
  setup: false,
  takedown: false,
  ref: false,
  extra: 0,
  monster: 0,
} as CharacterHours;
