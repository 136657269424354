import { createSlice } from '@reduxjs/toolkit';

import { RolesState } from '../../../../contracts/features/roles.types';

import {
  resetRolesReducer,
  fetchAllRolesFulfilledReducer,
  fetchAllRolesRejectedReducer,
  createRolesFulfilledReducer,
  createRolesRejectedReducer,
  fetchRoleFulfilledReducer,
  fetchRoleRejectedReducer,
  nameChangeReducer,
  updateSelectedPermissionsReducer,
  updateDraggingPermissionIdReducer,
  permissionListUpdateReducer,
  updateRoleFulfilledReducer,
  updateRoleRejectedReducer,
} from './roles.reducer';
import { createRole, fetchAllRoles, fetchSingleRole, updateRole } from './roles.actions';

export const initialState: RolesState = {
  error: '',
  draggingPermissionId: '',
  loading: false,
  list: [],
  roleEdit: { name: '', permissions: {} },
  selectedPermissions: [],
};

const rolesSlice = createSlice({
  name: 'roles',
  initialState,
  reducers: {
    resetRoles(state) {
      resetRolesReducer(state, { type: '' });
    },
    clearRole(state) {
      resetRolesReducer(state, { type: '' });
    },
    nameChange(state, payload) {
      nameChangeReducer(state, payload);
    },
    updateSelectedPermissions(state, payload) {
      updateSelectedPermissionsReducer(state, payload);
    },
    updateDraggingPermissionId(state, payload) {
      updateDraggingPermissionIdReducer(state, payload);
    },
    permissionListUpdate(state, payload) {
      permissionListUpdateReducer(state, payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllRoles.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAllRoles.fulfilled, fetchAllRolesFulfilledReducer)
      .addCase(fetchAllRoles.rejected, fetchAllRolesRejectedReducer);

    builder
      .addCase(fetchSingleRole.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchSingleRole.fulfilled, fetchRoleFulfilledReducer)
      .addCase(fetchSingleRole.rejected, fetchRoleRejectedReducer);

    builder
      .addCase(createRole.fulfilled, createRolesFulfilledReducer)
      .addCase(createRole.rejected, createRolesRejectedReducer);
    builder
      .addCase(updateRole.fulfilled, updateRoleFulfilledReducer)
      .addCase(updateRole.rejected, updateRoleRejectedReducer);
  },
});

export const {
  resetRoles,
  clearRole,
  nameChange,
  updateSelectedPermissions,
  updateDraggingPermissionId,
  permissionListUpdate,
} = rolesSlice.actions;

export default rolesSlice.reducer;
