import { RootState } from '../../../store/store';
import { ICellData, ISheetLayout } from '../types';

export const getId = (state: RootState): string => state.sheetModuleBuilder.id;
export const getLoading = (state: RootState): boolean => state.sheetModuleBuilder.isLoading;
export const getName = (state: RootState): string => state.sheetModuleBuilder.name;

export const getGridLayout = (state: RootState): ISheetLayout[] => state.sheetModuleBuilder.gridLayout;
export const getCustomVariables = (state: RootState): string[] => state.sheetModuleBuilder.customVariables || [];

export const getModalState = (state: RootState): boolean => state.sheetModuleBuilder.modal.isOpen;
export const getCellData = (state: RootState): ICellData => state.sheetModuleBuilder.modal.cellData;

export const getCellPosition = (state: RootState): { x: number; y: number } => state.sheetModuleBuilder.modal.position;
