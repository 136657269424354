import axios from 'axios';

const system = 'seaxe';

export const baseUrl = ``;

const url = `${baseUrl}/api/v2/users`;
const urlPass = `/api/v2/users`;

export const getUserById = (id) => axios.get(`${url}/${id}`).then((response) => response.data);

export const deleteUserByIdApi = (id) => axios.delete(`${url}/${id}`).then((response) => response.data);

export const updateUserApi = async (data) => {
  const payload = data;
  const response = await axios.put(`${url}/${payload.id}`, payload, {});

  return response.data;
};

export const updateUserRolesApi = async (data) => {
  const payload = data;
  const response = await axios.put(`${url}/${payload.id}/roles`, payload, {});

  return response.data;
};

export const updateUserPermissionsApi = (data, id) => {
  const payload = { system, permissions: data };

  return axios.put(`${url}/${id}/permissions`, payload, {}).then((res) => ({
    data: res.data,
  }));
};

export const removeUserPermissionsApi = (data, id) => {
  const payload = { system, permissions: data };

  return axios.put(`${url}/${id}/removePermissions`, payload, {}).then((res) => ({
    data: res.data,
  }));
};

export const updatePasswordApi = ({ password, newPassword, username, id }) => {
  if (!password || !newPassword || !username || !id) {
    throw Error(`Missing user details`);
  }

  const payload = {
    username,
    password,
    newPassword,
    id,
  };

  return axios.put(`${urlPass}/${id}/password`, payload, {}).then((response) => {
    return response.data;
  });
};

export const resetPassword = (email) => {
  const payload = {
    email,
  };

  return axios.post(`${urlPass}/auth/reset`, payload, {}).then((response) => {
    return response.data;
  });
};
