// Application name
export const NAME = 'roles';

// Action types

export const GET_ALL_ROLES = `${NAME}/GET_ALL_ROLES`;
export const GET_ALL_ROLES_ERROR = `${NAME}/GET_ALL_ROLES_ERROR`;
export const GET_ROLE = `${NAME}/GET_ROLE`;
export const CLEAR_ROLES = `${NAME}/CLEAR_ROLES`;
export const CLEAR_ROLE = `${NAME}/CLEAR_ROLE`;
export const SET_LOADING = `${NAME}/SET_LOADING`;
export const UPDATE_ROLE = `${NAME}/UPDATE_ROLE`;
export const UPDATE_SELECTED_PERMISSIONS = `${NAME}/UPDATE_SELECTED_PERMISSIONS`;
export const UPDATE_DRAGGING_PERMISSIONS = `${NAME}/UPDATE_DRAGGING_PERMISSIONS`;

// permissions list
// todo import these from one central location
// keep in sync with app/workflow/models/permission-types.js
export const PERMISSION_TYPES = {
  character: {
    view: 'CHARACTER_VIEW',
    // view characters
    editView: 'CHARACTER_EDIT_VIEW',
    // view edit characters
    create: 'CHARACTER_CREATE',
    // create characters
    update: 'CHARACTER_UPDATE',
    // update characters
    delete: 'CHARACTER_DELETE',
    // delete characters
  },
  tags: {
    view: 'TAGS_VIEW',
    // view tags
    create: 'TAGS_CREATE',
    // create tags
    update: 'TAGS_UPDATE',
    // update tags
    delete: 'TAGS_DELETE',
    // delete tags
  },
  search: {
    view: 'SEARCH_VIEW',
    // view search
  },
  // user: {
  //   view: 'USER_VIEW',
  //   // view own user details - possible remove
  //   update: 'USER_UPDATE',
  //   // update your own details - possible remove
  // },
  users: {
    view: 'USERS_VIEW',
    // view all users
    create: 'USERS_CREATE',
    // create a new user
    update: 'USERS_UPDATE',
    // update other users
    delete: 'USERS_DELETE',
    // delete users
  },
  role: {
    view: 'ROLE_VIEW',
    // view role
    create: 'ROLE_CREATE',
    // create role
    update: 'ROLE_UPDATE',
    // update role
    delete: 'ROLE_DELETE',
    // delete role
  },
  // system: {
  //   access: 'SYSTEM_ACCESS',
  //   // access to the system that the role is a part of - possible remove
  // },
  settings: {
    view: 'SETTINGS_VIEW',
    // view system settings
    update: 'SETTINGS_UPDATE',
    // update system settings
  },
  sheetModules: {
    view: 'SHEET_MODULES_VIEW',
    // view Character Sheet Builder
    create: 'SHEET_MODULES_CREATE',
    // create Character Sheet Builder
    update: 'SHEET_MODULES_UPDATE',
    // update Character Sheet Builder
    delete: 'SHEET_MODULES_DELETE',
    // delete Character Sheet Builder
  },
} as Record<string, Record<string, string>>;
