// Application name
export const NAME = 'search';

// Values

export const CHARACTERS_PER_PAGE = 12;

export const START_PAGE = 1;
// Action types

export const GET_CHARACTERS = `${NAME}/GET_CHARACTERS`;
export const GET_CHARACTERS_FAILURE = `${NAME}/GET_CHARACTERS_FAILURE`;
export const PAGE_CHANGE = `${NAME}/PAGE_CHANGE`;
export const FILTER_CHANGE = `${NAME}/FILTER_CHANGE`;
export const TYPE_CHANGE = `${NAME}/TYPE_CHANGE`;
export const RESET_SEARCH = `${NAME}/RESET_SEARCH`;
export const SEARCH = `${NAME}/SEARCH`;
export const SORT_CHANGE = `${NAME}/SORT_CHANGE`;
