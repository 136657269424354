import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkAction } from 'redux-thunk';
import { AnyAction } from 'redux';

import * as characterApi from '../../../services/character';
import { RootState } from '../../../store/store';
import { GET_CHARACTER } from '../character-view.constants';

export const getCharacterThunk = createAsyncThunk(GET_CHARACTER, (id: string) => {
  return characterApi.getCharacter(id);
});

export const getCharacter =
  (id: string): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch): Promise<void> => {
    await dispatch(getCharacterThunk(id));
    await dispatch(getCharacterThunk(id));
  };
