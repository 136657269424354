import styled, { keyframes } from 'styled-components';

const AnimLoader = keyframes`
  0% {
    top: 0;
    color: #2c2c2c;
  }
  50% {
    top: 30px;
    color: rgba(100, 100, 100, 0.4);
  }
  100% {
    top: 0;
    color: #2c2c2c;
  }
`;

export const LoadingWrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
`;

export const Loader = styled.div`
  width: 16px;
  height: 16px;
  box-shadow: 0 30px, 0 -30px;
  border-radius: 4px;
  background: currentColor;
  display: block;
  margin: -50px auto 0;
  position: relative;
  color: #2c2c2c;
  transform: translateY(30px);
  box-sizing: border-box;
  animation-name: ${AnimLoader};
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: ease;

  &::after {
    content: '';
    box-sizing: border-box;
    width: 16px;
    height: 16px;
    box-shadow: 0 30px, 0 -30px;
    border-radius: 4px;
    background: currentColor;
    color: #2c2c2c;
    position: absolute;
    left: 30px;
    top: 0;
    animation-name: ${AnimLoader};
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: ease;
    animation-delay: 0.1s;
  }

  &::before {
    left: 60px;
    content: '';
    box-sizing: border-box;
    width: 16px;
    height: 16px;
    box-shadow: 0 30px, 0 -30px;
    border-radius: 4px;
    background: currentColor;
    color: #2c2c2c;
    position: absolute;
    top: 0;
    animation-name: ${AnimLoader};
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: ease;
    animation-delay: 0.4s;
  }
`;
