import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkAction } from 'redux-thunk';
import { AnyAction } from 'redux';

import { SUBMIT } from '../experience.constants';
import * as characterApi from '../../../services/character';
import { RootState } from '../../../store/store';
import { HoursConfig } from '../../../../contracts/experience.types';

export const submitThunk = createAsyncThunk(SUBMIT, (payload: unknown) => {
  return characterApi.characterExperience(payload);
});

export const submit =
  (hoursConfig: HoursConfig): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch, getState): Promise<void> => {
    const state = getState();

    const { experience } = state;

    const { message, selectedCharacters } = experience;

    await dispatch(submitThunk({ characters: selectedCharacters, hoursConfig, message }));
  };
