import { ThunkAction } from 'redux-thunk';
import { AnyAction } from 'redux';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { RootState } from '../../../store/store';
import * as allCharactersApi from '../../../services/allCharacters';

export const fetchAllCharacters = createAsyncThunk('search/fetch-all-characters', () => {
  return allCharactersApi.getAllCharacters();
});

export const getAllCharacters =
  (): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch): Promise<void> => {
    await dispatch(fetchAllCharacters());
  };
