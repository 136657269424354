import React, { JSX, Fragment, Suspense, useEffect, useMemo } from 'react';
import { Switch } from 'wouter';
import { useDispatch, shallowEqual, useSelector } from 'react-redux';

import { initialAuthCheck } from '../features/authentication/redux/authentication.actions';
import {
  getInitialAuthCheck,
  getUserFirstTime,
  getUserLoggedInStatus,
} from '../features/authentication/redux/authentication.selectors';
import Spinner from '../components/loading-spinner/loading-spinner';
import Navigation from '../features/navigation/navigation.container';

import routeConstructor from './route.constructor';
import { SIGNED_OUT_ROUTES, SIGNED_IN_ROUTES, FIRST_TIME_ROUTES } from './routes.config';

const BaseRoutes = (): JSX.Element => {
  const dispatch = useDispatch();
  const authChecked = useSelector(getInitialAuthCheck, shallowEqual);
  const userLoggedIn = useSelector(getUserLoggedInStatus, shallowEqual);
  const userFirstTime = useSelector(getUserFirstTime, shallowEqual);

  useEffect(() => {
    if (!authChecked) {
      dispatch(initialAuthCheck());
    }
  }, [authChecked]);

  const signedOutRoutes = useMemo(() => routeConstructor(SIGNED_OUT_ROUTES), [SIGNED_OUT_ROUTES]);
  const signedInRoutes = useMemo(() => routeConstructor(SIGNED_IN_ROUTES), [SIGNED_IN_ROUTES]);
  const firstTimeRoutes = useMemo(() => routeConstructor(FIRST_TIME_ROUTES), [FIRST_TIME_ROUTES]);

  if (!authChecked) {
    return (
      <div className="auth-loading-spinner">
        <Spinner />
      </div>
    );
  }

  if (!userLoggedIn) {
    return (
      <Suspense
        fallback={
          <div>
            <Spinner />
          </div>
        }
      >
        <Switch>{signedOutRoutes}</Switch>
      </Suspense>
    );
  }

  if (userFirstTime) {
    return (
      <Fragment>
        <Suspense fallback={<Spinner />}>
          <Switch>{firstTimeRoutes}</Switch>
        </Suspense>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <Navigation>
        <Suspense fallback={<Spinner />}>
          <Switch>{signedInRoutes}</Switch>
        </Suspense>
      </Navigation>
    </Fragment>
  );
};
export default BaseRoutes;
