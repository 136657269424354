import { createSlice } from '@reduxjs/toolkit';

import { TagStore } from '../../../../contracts/features/tags.types';

import {
  fetchTagsThunk,
  fetchTagsDetailedThunk,
  fetchTagThunk,
  updateTagThunk,
  deleteTagThunk,
  newTagThunk,
} from './tag.actions';
import {
  fetchTagFulfilledReducer,
  fetchTagsFulfilledReducer,
  fetchTagsRejectedReducer,
  fetchTagRejectedReducer,
  updateNameReducer,
  updateTypeReducer,
  updateParentReducer,
  updateHiddenReducer,
  updateTagFulfilledReducer,
  updateTagRejectedReducer,
  deleteTagFulfilledReducer,
  deleteTagRejectedReducer,
  newTagThunkFulfilledReducer,
  newTagThunkRejectedReducer,
} from './tag.reducer';

export const initialState: TagStore = {
  tagList: [],
  tag: {
    id: '',
    name: '',
    type: 'guild',
  },
  loading: false,
};

const tagSlice = createSlice({
  name: 'tag',
  initialState,
  reducers: {
    clearTags(state) {
      state.tagList = [];
      state.tag = {
        id: '',
        name: '',
        type: 'guild',
      };
      state.loading = false;
    },
    updateName(state, action) {
      updateNameReducer(state, action);
    },
    updateType(state, action) {
      updateTypeReducer(state, action);
    },
    updateParent(state, action) {
      updateParentReducer(state, action);
    },
    updateHidden(state, action) {
      updateHiddenReducer(state, action);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTagsThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchTagsThunk.fulfilled, fetchTagsFulfilledReducer)
      .addCase(fetchTagsThunk.rejected, fetchTagsRejectedReducer);

    builder
      .addCase(fetchTagsDetailedThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchTagsDetailedThunk.fulfilled, fetchTagsFulfilledReducer)
      .addCase(fetchTagsDetailedThunk.rejected, fetchTagsRejectedReducer);

    builder
      .addCase(fetchTagThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchTagThunk.fulfilled, fetchTagFulfilledReducer)
      .addCase(fetchTagThunk.rejected, fetchTagRejectedReducer);

    builder
      .addCase(newTagThunk.fulfilled, newTagThunkFulfilledReducer)
      .addCase(newTagThunk.rejected, newTagThunkRejectedReducer);

    builder
      .addCase(updateTagThunk.fulfilled, updateTagFulfilledReducer)
      .addCase(updateTagThunk.rejected, updateTagRejectedReducer);

    builder
      .addCase(deleteTagThunk.fulfilled, deleteTagFulfilledReducer)
      .addCase(deleteTagThunk.rejected, deleteTagRejectedReducer);
  },
});

export const { clearTags, updateName, updateType, updateParent, updateHidden } = tagSlice.actions;

export default tagSlice.reducer;
