// Application name
export const NAME = 'authentication';

// Action types
export const LOGIN_CHECK_SUCCESS = `${NAME}/LOGIN_CHECK_SUCCESS`;
export const LOGIN_SUCCESS = `${NAME}/LOGIN_SUCCESS`;
export const LOGIN_SUCCESS_FAILED = `${NAME}/LOGIN_SUCCESS_FAILED`;
export const LOGOUT_SUCCESS = `${NAME}/LOGOUT_SUCCESS`;
export const UPDATE_AUTH_UPDATE = `${NAME}/UPDATE_AUTH_UPDATE`;
export const RESET_UPDATE_AUTH_UPDATE = `${NAME}/RESET_UPDATE_AUTH_UPDATE`;
export const UPDATE_USER_DATA = `${NAME}/UPDATE_USER_DATA`;
export const UPDATE_USER_DATA_FAILURE = `${NAME}/UPDATE_USER_DATA_FAILURE`;

export const INITIAL_AUTH_CHECK = `${NAME}/INITIAL_AUTH_CHECK`;
export const SET_USER = `${NAME}/SET_USER`;

export const RETRY_LIMIT = 5;
