import { CaseReducer, PayloadAction, SerializedError } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import { TagList, TagSelect, TagStore, TagType } from '../../../../contracts/features/tags.types';

export const fetchTagsFulfilledReducer: CaseReducer<TagStore, PayloadAction<TagList[]>> = (state, action) => {
  const { payload } = action;

  state.tagList = payload;
  state.loading = false;
};

export const fetchTagsRejectedReducer: CaseReducer<
  TagStore,
  PayloadAction<unknown, string, unknown, SerializedError>
> = (state, action) => {
  const { error } = action;
  state.tagList = [];
  state.loading = false;

  console.log('fetchTagsRejectedReducer - error', error.message);
};

export const fetchTagFulfilledReducer: CaseReducer<TagStore, PayloadAction<TagList>> = (state, action) => {
  const { payload } = action;

  state.tag = payload;
  state.loading = false;
};

export const fetchTagRejectedReducer: CaseReducer<
  TagStore,
  PayloadAction<unknown, string, unknown, SerializedError>
> = (state, action) => {
  const { error } = action;
  state.tag = {
    name: '',
    id: '',
    type: 'guild',
  };
  state.loading = false;

  console.log('fetchTagsRejectedReducer - error', error.message);
};

export const updateNameReducer: CaseReducer<TagStore, PayloadAction<string>> = (state, action) => {
  state.tag.name = action.payload;
};

export const updateTypeReducer: CaseReducer<TagStore, PayloadAction<{ value: TagType }>> = (state, action) => {
  state.tag.type = action.payload.value;
};

export const updateParentReducer: CaseReducer<TagStore, PayloadAction<TagSelect[]>> = (state, action) => {
  const data = action.payload.map((tag) => tag.value);
  state.tag.parents = data;
};

export const updateHiddenReducer: CaseReducer<TagStore, PayloadAction<boolean>> = (state, action) => {
  state.tag.hidden = action.payload;
};

export const updateTagFulfilledReducer: CaseReducer<TagStore, PayloadAction<TagList>> = (state, action) => {
  const { payload } = action;

  state.tag = payload;
  state.loading = false;

  toast.success('Update Tag Successfully');
};

export const updateTagRejectedReducer: CaseReducer<
  TagStore,
  PayloadAction<unknown, string, unknown, SerializedError>
> = (_state, action) => {
  const { error } = action;
  console.log('updateTagRejectedReducer - error', error.message);

  toast.error('failed to update tag');
};

export const newTagThunkFulfilledReducer: CaseReducer<TagStore, PayloadAction<TagList>> = () => {
  toast.success('Created Tag Successfully');
};

export const newTagThunkRejectedReducer: CaseReducer<
  TagStore,
  PayloadAction<unknown, string, unknown, SerializedError>
> = (_state, action) => {
  const { error } = action;
  console.log('updateTagRejectedReducer - error', error.message);

  toast.error('failed to Created Tag');
};

export const deleteTagFulfilledReducer: CaseReducer<TagStore, PayloadAction<TagList>> = () => {
  toast.success('Tag deleted Successfully');
};

export const deleteTagRejectedReducer: CaseReducer<
  TagStore,
  PayloadAction<unknown, string, unknown, SerializedError>
> = (_state, action) => {
  const { error } = action;
  console.log('deleteTagRejectedReducer - error', error.message);

  toast.error('failed to delete tag');
};
