import axios from 'axios';

import { baseUrl } from '../constants';

const system = 'seaxe';

const url = `${baseUrl}/${system}/api/v2/roles`;

export const getById = (id) => axios.get(`${url}/${id}`).then((response) => response.data);

export const getAll = () => axios.get(`${url}`).then((response) => response.data);

export const save = (id, payload) => {
  return axios.put(`${url}/${id}`, payload).then((response) => response.data);
};

export const create = (name) => axios.post(`${url}`, { name }).then((response) => response.data);
