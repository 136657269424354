import _set from 'lodash/set';

import { defaultCell } from '../constants/landscape-grid-layout';
import { ICellData, ISheetLayout } from '../types';

const trueStart = (start: number, end: number): { start: number; end: number } => {
  if (start < end) {
    return { start, end };
  }
  return { start: end, end: start };
};

const getStartCellWidth = ({
  gridLayout,
  row,
  start,
}: {
  gridLayout: ISheetLayout[];
  row: number;
  start: number;
}): { startColumnWidth: number; startColumnId: number; rowData: ICellData[]; rowId: number } => {
  // find row index
  const rowId = gridLayout.findIndex((rowData) => rowData.rowPosition === row);

  const rowData = gridLayout[rowId].columns || [];
  // find column index
  const startColumnId = rowData.findIndex((columnData) => columnData.columnPosition === start);

  if (startColumnId < 0) {
    throw new Error('column not found');
  }

  const width = rowData[startColumnId].width;

  return { startColumnWidth: width, startColumnId, rowData, rowId };
};

// cant be ts due to immutable issues
export const calculateDifference = ({
  row,
  gridLayout,
  startColumn,
  endColumn,
}: {
  gridLayout: ISheetLayout[];
  row: number;
  startColumn: number;
  endColumn: number;
}): { difference: number; startIndex: number; endIndex: number } => {
  const { start, end } = trueStart(startColumn, endColumn);

  const { startColumnWidth } = getStartCellWidth({ gridLayout, row, start });

  const cellDifference = Math.abs(start - end);

  const trueDifference = cellDifference - startColumnWidth + 1;

  return { difference: trueDifference, startIndex: start, endIndex: end };
};

export const calculateMergedCellData = ({
  gridLayout,
  startColumn,
  difference,
  row,
}: {
  gridLayout: ISheetLayout[];
  row: number;
  startColumn: number;
  difference: number;
}): ISheetLayout[] => {
  const { startColumnWidth, startColumnId, rowData, rowId } = getStartCellWidth({
    gridLayout,
    row,
    start: startColumn,
  });

  // check for any merged cells
  for (let i = startColumnId; i < startColumnId + difference + 1; i++) {
    const rowWidth = rowData[i].width;

    if (rowWidth > 1) {
      throw new Error('can not merge rows already merged, please unmerge and try again');
    }
  }

  // remove all cells before start and after start + difference
  const prunedRowData = rowData.reduce((acc, rowItem) => {
    if (rowItem.columnPosition <= startColumn) {
      return [...acc, rowItem];
    }
    if (rowItem.columnPosition > startColumn + difference) {
      return [...acc, rowItem];
    }

    return acc;
  }, [] as ICellData[]);

  const column = prunedRowData[startColumnId];
  const updatedColumn = { ...column, width: difference + startColumnWidth };

  // update width
  const updatedRowData = _set(prunedRowData, [startColumnId], updatedColumn);

  const originalRow = gridLayout[rowId];

  const updatedRow = { ...originalRow, columns: updatedRowData };

  return _set(gridLayout, [rowId], updatedRow);
};

export const calculateUnmergedCellData = ({
  gridLayout,
  cellData,
  position,
}: {
  gridLayout: ISheetLayout[];
  position: { x: number; y: number };
  cellData: ICellData;
}): ISheetLayout[] => {
  // find row index
  const rowId = gridLayout.findIndex((rowData) => rowData.rowPosition === position.y + 1);
  const rowData = gridLayout[rowId].columns;
  // find column index

  const startId = cellData.columnPosition;
  const startColumnId = rowData.findIndex((columnData) => columnData.columnPosition === startId);

  if (startColumnId < 0) {
    throw new Error('column not found');
  }

  const startColumnWidth = cellData.width;
  const cellsToInsert = [];

  for (let i = startId + 1; i < startId + startColumnWidth; i++) {
    cellsToInsert.push({ columnPosition: i, ...defaultCell });
  }

  // insert new cells
  rowData.splice(startColumnId + 1, 0, ...cellsToInsert);

  const updateCellWidth = _set(rowData, [startColumnId, 'width'], 1);
  const updatedColumn = _set(gridLayout, [rowId, 'columns'], updateCellWidth);

  return updatedColumn;
};

export const validateCellId = ({
  grid,
  cellId,
  position,
}: {
  grid: ISheetLayout[];
  position: { x: number; y: number };
  cellId: string;
}): boolean => {
  if (!cellId) {
    return false;
  }
  return grid.reduce((rowAcc, row) => {
    const rowId = row.rowPosition;
    const cellFound = row.columns.reduce((columnAcc, column) => {
      const columnId = column.columnPosition;
      if (position.x + 1 === columnId && position.y + 1 === rowId) {
        return columnAcc;
      }
      if (column.cellId === cellId) {
        return true;
      }

      return columnAcc;
    }, false);

    if (cellFound) {
      return cellFound;
    }
    return rowAcc;
  }, false);
};
