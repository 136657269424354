import { createSlice } from '@reduxjs/toolkit';

import { CharacterViewState } from '../../../../contracts/features/characterView.types';

import { getCharacterThunk } from './character-view.actions';
import {
  characterGetFulfilledReducer,
  characterGetRejectedReducer,
  resetCharacterReducer,
} from './character-view.reducer';

export const initialState: CharacterViewState = {
  details: {
    lastName: '',
    npc: false,
    retired: false,
    unspentXp: 0,
    displayName: '',
    user_id: '',
    system: '',
    characterName: '',
    sheetType: '',
    id: '',
    firstName: '',
    notes: '',
    totalXp: 0, // might not be needed
    customPlayerName: '',
    sheetmodules: [],
    sheetOrder: [],
    changeLog: [],
    tags: [],
    dynamicTags: [],
    customGlobals: {},
    sheetData: [],
  },
  error: '',
};

const characterViewSlice = createSlice({
  name: 'characterView',
  initialState,
  reducers: {
    resetCharacter(state) {
      resetCharacterReducer(state, { type: '' });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCharacterThunk.fulfilled, characterGetFulfilledReducer)
      .addCase(getCharacterThunk.rejected, characterGetRejectedReducer);
  },
});

export const { resetCharacter } = characterViewSlice.actions;

export default characterViewSlice.reducer;
