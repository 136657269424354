import { CaseReducer, PayloadAction, SerializedError } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import _set from 'lodash/set';
import _sortBy from 'lodash/sortBy';

import {
  CharacterEditorState,
  CharacterSheet,
  GetCharacterResponse,
  UpdateCharacterSheetData,
} from '../../../../contracts/features/character-editor.types';
import { UpdateCharacterData } from '../types';
import { SheetOrder } from '../../../../contracts/character.types';
import { CHARACTER_NAME } from '../../sheet-module-builder/sheet-module-builder.constants';
import { SheetModule } from '../../../../contracts/features/sheet-modules.types';
import { User } from '../../../../contracts/features/users.types';
import { TagList } from '../../../../contracts/features/tags.types';

export const characterGetFulfilledReducer: CaseReducer<
  CharacterEditorState,
  PayloadAction<GetCharacterResponse, string, { arg: string; requestId: string; requestStatus: 'fulfilled' }, never>
> = (state, action) => {
  const { payload } = action;
  state.characterSheet = payload;
};

export const characterGetRejectedReducer: CaseReducer<
  CharacterEditorState,
  PayloadAction<unknown, string, unknown, SerializedError>
> = (_state, action) => {
  const { error } = action;
  console.error('authenticationCheckRejectedReducer - error', error.message);
  toast.error('Failed to fetch character');
};

export const saveCharacterFulfilledReducer: CaseReducer<
  CharacterEditorState,
  PayloadAction<
    GetCharacterResponse,
    string,
    { arg: CharacterSheet; requestId: string; requestStatus: 'fulfilled' },
    never
  >
> = (state, action) => {
  const { payload } = action;
  state.characterSheet = payload;
};

export const updateCharacterReducer: CaseReducer<CharacterEditorState, PayloadAction<UpdateCharacterData>> = (
  state,
  action
): void => {
  const { payload } = action;

  const { field, data } = payload;
  const { characterSheet } = state;

  if (field in characterSheet) {
    // @ts-expect-error // todo return after ts bump
    characterSheet[field] = data;
  }
};

export const updateCharacterSheetDataReducer: CaseReducer<
  CharacterEditorState,
  PayloadAction<UpdateCharacterSheetData>
> = (state, action): void => {
  const { payload } = action;

  const { value, sheetKey, path } = payload;

  const { sheetData } = state.characterSheet;

  const selectedSheet = sheetData.find((sheet) => sheet.sheetKey === sheetKey);

  if (!selectedSheet) {
    const newSheet = {
      sheetKey,
      [path]: value,
    };

    sheetData.push(newSheet);
    state.formIsHalfFilledOut = true;
    return;
  }

  selectedSheet[path] = value;
  state.formIsHalfFilledOut = true;
};

const getTagParents = (id: string, tagList: TagList[]): string[] => {
  const foundTag = tagList.find((tag) => tag.id === id);

  if (!foundTag) {
    return [];
  }

  if (!foundTag.parents?.length) {
    return [foundTag.id];
  }

  const includeParents = foundTag.parents.reduce((acc: string[], parent) => {
    const parents = getTagParents(parent, tagList);

    return [...acc, ...parents];
  }, []);

  return [foundTag.id, ...includeParents];
};

export const updateCharacterSheetDataWithTagsReducer: CaseReducer<
  CharacterEditorState,
  PayloadAction<UpdateCharacterSheetData>
> = (state, action): void => {
  const { payload } = action;

  const { value, sheetKey, path, tags = [], previousValue } = payload;

  const { sheetData } = state.characterSheet;

  const selectedSheet = sheetData.find((sheet) => sheet.sheetKey === sheetKey);

  const currentDynamicTags = state.characterSheet.dynamicTags || [];
  const tagsToRemove = getTagParents(`${previousValue}`, tags);
  const tagsToAdd = getTagParents(`${value}`, tags);

  const removedOldTags = currentDynamicTags.reduce((acc: string[], tag) => {
    if (tagsToRemove.includes(tag)) {
      return acc;
    }

    return [...acc, tag];
  }, []);

  state.characterSheet.dynamicTags = [...removedOldTags, ...tagsToAdd];

  // should be last, logic to create a new sheet object if one doesn't exist
  if (!selectedSheet) {
    const newSheet = {
      sheetKey,
      [path]: value,
    };

    sheetData.push(newSheet);
    state.formIsHalfFilledOut = true;
  } else {
    selectedSheet[path] = value;
    state.formIsHalfFilledOut = true;
  }
};

export const updateCharacterSheetDataUserReducer: CaseReducer<CharacterEditorState, PayloadAction<User>> = (
  state,
  action
): void => {
  const { payload } = action;

  const { id, firstName, lastName, displayName } = payload;

  state.characterSheet.firstName = firstName;
  state.characterSheet.lastName = lastName;
  state.characterSheet.displayName = displayName;
  state.characterSheet.user_id = id;

  state.formIsHalfFilledOut = true;
};

export const updateCharacterSheetLinkedDataReducer: CaseReducer<
  CharacterEditorState,
  PayloadAction<UpdateCharacterData>
> = (state, action): void => {
  const { payload } = action;

  const { field, data } = payload;
  const { characterSheet } = state;

  if (field === CHARACTER_NAME && typeof data === 'string') {
    characterSheet.characterName = data;
    state.formIsHalfFilledOut = true;
  }

  characterSheet.customGlobals[field] = data;
  state.formIsHalfFilledOut = true;
};

export const sheetSelectedReducer: CaseReducer<
  CharacterEditorState,
  PayloadAction<{ value: string; label: string }>
> = (state, action): void => {
  const { payload } = action;
  state.options.selected = payload;
  state.formIsHalfFilledOut = true;
};

export const addSheetReducer: CaseReducer<CharacterEditorState, PayloadAction<{ data: SheetModule }>> = (
  state,
  action
): void => {
  const { payload } = action;

  const { data } = payload;

  state.characterSheet.sheetmodules = [...(state.characterSheet.sheetmodules || []), data];
  state.formIsHalfFilledOut = true;
};

export const removeSheetReducer: CaseReducer<CharacterEditorState, PayloadAction<string>> = (state, action): void => {
  const { payload } = action;

  const itemPosition = payload;

  const sheetOrder = state.characterSheet.sheetOrder;
  const position = parseInt(itemPosition, 10);
  const index = sheetOrder.findIndex((sheet) => sheet.position === position);
  state.characterSheet.sheetOrder = sheetOrder.filter((_sheet, sheetIndex) => sheetIndex !== index);
};

export const sheetReOrderReducer: CaseReducer<CharacterEditorState, PayloadAction<SheetOrder[]>> = (
  state,
  action
): void => {
  const { payload } = action;
  state.characterSheet.sheetOrder = payload;
  state.formIsHalfFilledOut = true;
};

export const moveItemUpReducer: CaseReducer<CharacterEditorState, PayloadAction<string>> = (state, action): void => {
  const { payload } = action;

  const itemPosition = payload;

  const sheetOrder = state.characterSheet.sheetOrder;
  const position = parseInt(itemPosition, 10);

  const index = sheetOrder.findIndex((sheet) => sheet.position === position);

  const replacedItemIndex = index - 1;

  if (replacedItemIndex < 0) {
    return;
  }

  const updatedItem = _set(sheetOrder, [index, 'position'], position - 1);
  const updatedReplacedItem = _set(updatedItem, [replacedItemIndex, 'position'], position);

  state.characterSheet.sheetOrder = _sortBy(updatedReplacedItem, ['position']);
  state.formIsHalfFilledOut = true;
};

export const moveItemDownReducer: CaseReducer<CharacterEditorState, PayloadAction<string>> = (state, action): void => {
  const { payload } = action;
  const itemPosition = payload;
  const sheetOrder = state.characterSheet.sheetOrder;

  const position = parseInt(itemPosition, 10);

  const index = sheetOrder.findIndex((sheet) => sheet.position === position);

  const replacedItemIndex = index + 1;

  if (replacedItemIndex < 0) {
    return;
  }

  const updatedItem = _set(sheetOrder, [index, 'position'], position + 1);
  const updatedReplacedItem = _set(updatedItem, [replacedItemIndex, 'position'], position);

  state.characterSheet.sheetOrder = _sortBy(updatedReplacedItem, ['position']);
  state.formIsHalfFilledOut = true;
};

export const resetCharacterReducer: CaseReducer<CharacterEditorState> = (state): void => {
  state.characterSheet = {
    lastName: '',
    npc: false,
    retired: false,
    unspentXp: 0,
    displayName: '',
    user_id: '',
    system: '',
    characterName: '',
    sheetType: '',
    id: '',
    firstName: '',
    notes: '',
    totalXp: 0, // might not be needed
    customPlayerName: '',
    sheetmodules: [],
    sheetOrder: [],
    changeLog: [],
    tags: [],
    dynamicTags: [],
    customGlobals: {},
    sheetData: [],
  };
  state.options = {
    selected: {
      value: '',
      label: '',
    },
    selectedData: undefined,
    sheetModuleLoading: false,
  };
  state.formIsHalfFilledOut = false;
  state.createdNewCharacter = false;
};

export const setupBaseCharacterReducer: CaseReducer<CharacterEditorState> = (state): void => {
  state.characterSheet = {
    characterName: '',
    sheetData: [{ sheetKey: 'sheet-1' }, { sheetKey: 'sheet-2' }],
    system: 'seaxe',
    npc: false,
    retired: false,
    totalXp: 0,
    unspentXp: 0,
    tags: [],
    dynamicTags: [],
    changeLog: [],
    customPlayerName: '',
    sheetmodules: [],
    sheetType: 'modular',
    lastName: '',
    displayName: '',
    user_id: '',
    notes: '',
    id: '',
    firstName: '',
    sheetOrder: [],
    customGlobals: {},
  };
};

export const createCharacterFulfilledReducer: CaseReducer<
  CharacterEditorState,
  PayloadAction<
    GetCharacterResponse,
    string,
    { arg: CharacterSheet; requestId: string; requestStatus: 'fulfilled' },
    never
  >
> = (state, action) => {
  const { payload } = action;
  state.characterSheet = payload;
  state.createdNewCharacter = true;
  state.formIsHalfFilledOut = false;
  toast.success('Character created successfully');
};

export const createCharacterRejectedReducer: CaseReducer<
  CharacterEditorState,
  PayloadAction<unknown, string, unknown, SerializedError>
> = (_state, action) => {
  const { error } = action;
  console.error('createCharacterRejectedReducer - error', error.message);
  toast.error('Failed to create a new character');
};

export const deleteCharacterFulfilledReducer: CaseReducer<
  CharacterEditorState,
  PayloadAction<unknown, string, { requestId: string; requestStatus: 'fulfilled' }, never>
> = () => {
  toast.success('Character deleted successfully');
};

export const deleteCharacterRejectedReducer: CaseReducer<
  CharacterEditorState,
  PayloadAction<unknown, string, unknown, SerializedError>
> = (_state, action) => {
  const { error } = action;
  console.error('deleteCharacterRejectedReducer - error', error.message);
  toast.error('Failed to delete character');
};
