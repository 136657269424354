import { createSlice } from '@reduxjs/toolkit';

import { UsersState } from '../../../../contracts/features/users.types';

import { fetchListOfUsers, fetchUserById, updateSingleUser, deleteUserById } from './users.actions';
import {
  fetchListOfUsersFulfilledReducer,
  fetchListOfUsersRejectedReducer,
  fetchUserByIdFulfilledReducer,
  fetchUserByIdRejectedReducer,
  updateUserDetailsReducer,
  updateSingleUserFulfilledReducer,
  updateSingleUserRejectedReducer,
  resetUsersReducer,
  deleteSingleUserFulfilledReducer,
  deleteSingleUserRejectedReducer,
} from './users.reducer';

export const initialState: UsersState = {
  id: '',
  username: '',
  email: '',
  firstName: '',
  lastName: '',
  roles: [],
  list: [],
};

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    resetUsers(state) {
      resetUsersReducer(state, { type: '' });
    },
    updateUserDetails(state, action) {
      updateUserDetailsReducer(state, action);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchListOfUsers.fulfilled, fetchListOfUsersFulfilledReducer)
      .addCase(fetchListOfUsers.rejected, fetchListOfUsersRejectedReducer);

    builder
      .addCase(fetchUserById.fulfilled, fetchUserByIdFulfilledReducer)
      .addCase(fetchUserById.rejected, fetchUserByIdRejectedReducer);

    builder
      .addCase(updateSingleUser.fulfilled, updateSingleUserFulfilledReducer)
      .addCase(updateSingleUser.rejected, updateSingleUserRejectedReducer);

    builder
      .addCase(deleteUserById.fulfilled, deleteSingleUserFulfilledReducer)
      .addCase(deleteUserById.rejected, deleteSingleUserRejectedReducer);
  },
});

export const { resetUsers, updateUserDetails } = usersSlice.actions;

export default usersSlice.reducer;
