import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { Router } from 'wouter';
import Modal from 'react-modal';

import BaseRoutes from './routes/base-routes';
import { store } from './store/store';
import { useLocationWithConfirmation } from './routes/router-prompt';

Modal.setAppElement('#app');

export default (): void => {
  ReactDOM.render(
    <Provider store={store}>
      {/* @ts-ignore*/}
      <Router hook={useLocationWithConfirmation}>
        <BaseRoutes />
      </Router>
      <ToastContainer />
    </Provider>,
    document.getElementById('app')
  );
};
