export const NAME = 'sheetModulesEditor';

// Action types

export const GET_SHEET_MODULE = `${NAME}/GET_SHEET_MODULE`;
export const SAVE_SHEET_MODULE = `${NAME}/SAVE_SHEET_MODULE`;

// sheet

export const CHARACTER_NAME = 'Character Name';
export const PLAYER_NAME = 'Player Name';
export const EXPERIENCE = 'Experience';

export const FIXED_SHEET_VARIABLES = [PLAYER_NAME, EXPERIENCE];
export const DYNAMIC_SHEET_VARIABLES = [CHARACTER_NAME];
export const ALIGNMENT_VARIABLES = ['center', 'left', 'right'];

// type constants

export const TEXT = 'text';
export const TOGGLE = 'toggle';
export const LINK = 'link';
export const FIXED = 'fixed';
export const VARIABLE = 'variable';
export const VIA_TAG = 'via-tag';
export const BLANK = 'blank';
