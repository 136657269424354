import { CaseReducer, PayloadAction, SerializedError } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import {
  AuthCheckResponse,
  AuthenticationConfig,
  LoginUser,
} from '../../../../contracts/features/authentication.types';
import { UserDetails } from '../../../../contracts/features/users.types';

export const loggedOutReducer: CaseReducer<AuthenticationConfig, PayloadAction<AuthenticationConfig>> = (state) => {
  state.loggedIn = false;
  state.error = '';
  state.initialAuthCheck = false;
  state.user = {
    email: '',
    username: '',
    firstName: '',
    lastName: '',
    firstTime: false,
    id: '',
  };
  state.userDetails = {
    email: '',
    username: '',
    firstName: '',
    lastName: '',
    firstTime: false,
    id: '',
  };
  state.roles = [];
  state.message = '';
  state.signIn = '';
};

export const initialAuthenticationCheckFulfilledReducer: CaseReducer<
  AuthenticationConfig,
  PayloadAction<AuthenticationConfig>
> = (state, action) => {
  const { payload } = action;
  const { user, roles } = payload;
  state.loggedIn = true;
  state.user = user;
  state.userDetails = user;
  state.roles = roles;
  state.error = '';
  state.initialAuthCheck = true;
};

export const initialAuthenticationCheckRejectedReducer: CaseReducer<
  AuthenticationConfig,
  PayloadAction<unknown, string, unknown, SerializedError>
> = (state, action): void => {
  const { error } = action;
  console.log('authenticationCheckRejectedReducer - error', error.message);
  state.initialAuthCheck = true;
  state.error = '';
};

export const loginUserFulfilledReducer: CaseReducer<
  AuthenticationConfig,
  PayloadAction<AuthCheckResponse, string, { arg: LoginUser; requestId: string; requestStatus: 'fulfilled' }, never>
> = (state) => {
  state.signIn = 'Fulfilled';
  state.error = '';
};

export const loginUserRejectedReducer: CaseReducer<
  AuthenticationConfig,
  PayloadAction<unknown, string, unknown, SerializedError>
> = (state, action) => {
  const { error } = action;
  state.signIn = 'Rejected';
  console.log('loginUserRejectedReducer - error', error.message);
  state.error = 'Invalid Username or Password';
};

export const authenticationCheckFulfilledReducer: CaseReducer<
  AuthenticationConfig,
  PayloadAction<AuthenticationConfig>
> = (state, action) => {
  const { payload } = action;
  const { user, roles } = payload;
  state.user = user;
  state.userDetails = user;
  state.roles = roles;
  state.loggedIn = true;
  state.error = '';
};

export const authenticationCheckRejectedReducer: CaseReducer<
  AuthenticationConfig,
  PayloadAction<unknown, string, unknown, SerializedError>
> = (_state, action): void => {
  const { error } = action;
  console.log('authenticationCheckRejectedReducer - error', error.message);
};

export const resetUserPasswordFulfilledReducer: CaseReducer<
  AuthenticationConfig,
  PayloadAction<string, string, { arg: string; requestId: string; requestStatus: 'fulfilled' }, never>
> = (state) => {
  toast.success('Password reset, new password emailed');
  state.loggedIn = false;
  state.error = '';
};

export const resetUserPasswordRejectedReducer: CaseReducer<
  AuthenticationConfig,
  PayloadAction<unknown, string, unknown, SerializedError>
> = (state) => {
  toast.error('Password Reset failed');
  state.loggedIn = false;
  state.error = 'Password Reset failed';
};

export const updateUserDetailsReducer: CaseReducer<AuthenticationConfig, PayloadAction<Partial<UserDetails>>> = (
  state,
  action
) => {
  const { payload } = action;
  state.userDetails.id = payload?.id || state.userDetails.id;
  state.userDetails.username = payload?.username || state.userDetails.username;
  state.userDetails.email = payload?.email || state.userDetails.email;
  state.userDetails.firstName = payload?.firstName || state.userDetails.firstName;
  state.userDetails.lastName = payload?.lastName || state.userDetails.lastName;
};

export const updateUserFulfilledReducer: CaseReducer<
  AuthenticationConfig,
  PayloadAction<string, string, { requestStatus: 'fulfilled' }, never>
> = (state) => {
  toast.success('User Details Updated');

  state.error = '';
};

export const updateUserRejectedReducer: CaseReducer<
  AuthenticationConfig,
  PayloadAction<unknown, string, unknown, SerializedError>
> = (state) => {
  console.error('failed to update user details');
  toast.error('User Details Failed to Update');

  state.error = 'Update user details failed';
};
