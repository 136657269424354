import axios from 'axios';

import { baseUrl } from '../constants';

const system = 'seaxe';

const urlCharacter = `${baseUrl}/${system}/api/v2/characterSheets`;

const url = `${baseUrl}/${system}/api/v2/characterSheets`;

export const getCharacter = (id) => axios.get(`${urlCharacter}/${id}`).then((response) => response.data);

const getId = (data) => data.id;

export const saveCharacter = (data) => {
  const id = getId(data);

  return axios.put(`${urlCharacter}/${id}`, data).then((response) => response.data);
};

export const createCharacter = (data) => {
  return axios.post(`${url}`, data).then((response) => response.data);
};

export const upgradeCharacter = (id) => axios.post(`${urlCharacter}/upgrade/${id}`).then((response) => response.data);

export const deleteCharacter = (id) => {
  return axios.delete(`${urlCharacter}/${id}`).then((response) => response.data);
};

export const characterExperience = (payload) =>
  axios.post(`${urlCharacter}/experience`, payload).then((response) => response.data);

export default { getCharacter, saveCharacter, createCharacter };

/*
 import axios from 'axios';

 import { baseUrl } from '../constants';
 import { CharacterSheet } from '../../contracts/features/character-editor.types';

 const system = 'seaxe';

 const urlCharacter = `${baseUrl}/${system}/api/v2/characterSheets`;

 const url = `${baseUrl}/${system}/api/v2/characterSheets`;

 export const getCharacter = (id: string): Promise<CharacterSheet> =>
 axios.get(`${urlCharacter}/${id}`).then((response) => response.data);

 const getId = (data: CharacterSheet): string => data.id;

 export const saveCharacter = (data: CharacterSheet): Promise<CharacterSheet> => {
 const id = getId(data);

 return axios.put(`${urlCharacter}/${id}`, data).then((response) => response.data);
 };

 export const createCharacter = (data: CharacterSheet): Promise<CharacterSheet> => {
 return axios.post(`${url}`, data).then((response) => response.data);
 };

 export const upgradeCharacter = (id: string): Promise<unknown> =>
 axios.post(`${urlCharacter}/upgrade/${id}`).then((response) => response.data);

 // export const deleteCharacter = (id) => {
 //   return axios.del(`${urlCharacter}/${id}`).then((response) => response.data);
 // };

 export const characterExperience = (payload: unknown): Promise<unknown> =>
 axios.post(`${urlCharacter}/experience`, payload).then((response) => response);

 export default { getCharacter, saveCharacter, createCharacter };

 */
