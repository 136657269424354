import { SearchCharacter, SearchStore } from '../../../../../contracts/features/search.types';
import * as types from '../../search.constants';

import { calculatePagesCount, filterData, sortCharacters } from './sorter';

const dataStart = 0;
const dataEnd = types.CHARACTERS_PER_PAGE;

type ArrangeCharactersReturn = {
  serverData: SearchCharacter[];
  characterList: SearchCharacter[];
  characterCount: number;
  pageCount: number;
  page: number;
  pageData: SearchCharacter[];
};

type CharacterProcessorAttr = {
  characterList: SearchCharacter[];
  sortBy: string;
  direction: string;
  filter: string;
};

export const characterProcessor = ({
  characterList,
  sortBy,
  direction,
  filter,
}: CharacterProcessorAttr): ArrangeCharactersReturn => {
  const formattedList = characterList.map((character) => ({
    ...character,
    lastModified: character?.lastModified || character.updated_at,
  }));

  const sortedCharacterList = sortCharacters(formattedList, sortBy, direction);

  const serverData = [...formattedList];

  const filteredCharacterList = filterData(filter, sortedCharacterList);
  const characterCount = filteredCharacterList.length;
  const pageCount = calculatePagesCount(characterCount);

  const pageData = filteredCharacterList.slice(dataStart, dataEnd);

  return {
    serverData,
    characterList: filteredCharacterList,
    characterCount,
    pageCount,
    page: types.START_PAGE,
    pageData,
  };
};

export const processCharacters = (
  characterState: SearchStore,
  characterList: SearchCharacter[]
): ArrangeCharactersReturn => {
  const { sortBy, direction, filter } = characterState;

  return characterProcessor({ characterList, sortBy, direction, filter });
};

export const processCharactersWithFilter = (
  characterState: SearchStore,
  characterList: SearchCharacter[],
  filter: string
): ArrangeCharactersReturn => {
  const { sortBy, direction } = characterState;

  return characterProcessor({ characterList, sortBy, direction, filter });
};
