import { createSlice } from '@reduxjs/toolkit';

import landscapeGridLayout from '../constants/landscape-grid-layout';
import { SheetModuleBuilderState } from '../../../../contracts/features/sheet-module-builder.types';

import { getSheetModuleThunk, saveSheetModuleThunk } from './sheet-module-builder.actions';
import {
  getSheetModuleFulfilledReducer,
  getSheetModuleRejectedReducer,
  saveSheetModuleFulfilledReducer,
  saveSheetModuleRejectedReducer,
  mergeCellsReducer,
  openModalReducer,
  closeModalReducer,
  updateCellDataReducer,
  unmergeCellReducer,
  clearCellDataReducer,
  copyCellDataReducer,
  pasteCellDataReducer,
  saveCellDataReducer,
  addCustomVariableReducer,
  removeCustomVariableReducer,
} from './sheet-module-builder.reducer';

export const defaultModalData = {
  isOpen: false,
  position: { x: 0, y: 0 },
  cellData: {
    cellId: '',
    columnPosition: 0,
    height: 0,
    width: 0,
    style: '',
    type: '',
    value: '',
    alignment: '',
    checked: false,
  },
};

export const initialState: SheetModuleBuilderState = {
  gridLayout: landscapeGridLayout,
  customVariables: [],
  globalVariables: {
    characterName: '',
    userName: '',
    xp: '',
  },
  id: '',
  name: '',
  modal: defaultModalData,
  isLoading: false,
  error: '',
};

const statLineSlice = createSlice({
  name: 'sheetModuleBuilder',
  initialState,
  reducers: {
    teardown(state) {
      state.gridLayout = landscapeGridLayout;
      state.customVariables = [];
      state.globalVariables = {
        characterName: '',
        userName: '',
        xp: '',
      };
      state.id = '';
      state.name = '';
      state.modal = defaultModalData;
      state.isLoading = false;
      state.error = '';
    },

    mergeCells(state, action) {
      mergeCellsReducer(state, action);
    },
    openModal(state, action) {
      openModalReducer(state, action);
    },
    closeModal(state) {
      closeModalReducer(state, { type: null });
    },
    updateCellData(state, action) {
      updateCellDataReducer(state, action);
    },
    unmergeCell(state, action) {
      unmergeCellReducer(state, action);
    },
    clearCellData(state, action) {
      clearCellDataReducer(state, action);
    },
    copyCellData(state, action) {
      copyCellDataReducer(state, action);
    },
    pasteCellData(state, action) {
      pasteCellDataReducer(state, action);
    },
    saveCellData(state, action) {
      saveCellDataReducer(state, action);
    },
    addCustomVariable(state, action) {
      addCustomVariableReducer(state, action);
    },
    removeCustomVariable(state, action) {
      removeCustomVariableReducer(state, action);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSheetModuleThunk.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getSheetModuleThunk.fulfilled, getSheetModuleFulfilledReducer)
      .addCase(getSheetModuleThunk.rejected, getSheetModuleRejectedReducer);
    builder
      .addCase(saveSheetModuleThunk.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(saveSheetModuleThunk.fulfilled, saveSheetModuleFulfilledReducer)
      .addCase(saveSheetModuleThunk.rejected, saveSheetModuleRejectedReducer);
  },
});

export const {
  teardown,
  mergeCells,
  updateCellData,
  openModal,
  closeModal,
  copyCellData,
  pasteCellData,
  unmergeCell,
  clearCellData,
  saveCellData,
  addCustomVariable,
  removeCustomVariable,
} = statLineSlice.actions;

export default statLineSlice.reducer;
