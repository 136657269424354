import { userLogin } from '../../../services/login';
import { AuthCheckResponse, LoginUser } from '../../../../contracts/features/authentication.types';

export const retryUserLogin = async (credentials: LoginUser, retryCount: number): Promise<AuthCheckResponse> => {
  try {
    return await userLogin(credentials);
  } catch (err) {
    if (retryCount <= 1) {
      throw err;
    }
    return retryUserLogin(credentials, retryCount - 1);
  }
};
