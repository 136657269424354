import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkAction } from 'redux-thunk';
import { AnyAction } from 'redux';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';

import * as characterApi from '../../../services/character';
import {
  CREATE_CHARACTER,
  defaultSheets,
  DELETE_CHARACTER,
  GET_CHARACTER,
  MY_CHARACTERS,
  SAVE_CHARACTER,
  SEARCH_CHARACTERS,
} from '../characterEditor.constants';
import { RootState } from '../../../store/store';
import { generateSheetKey } from '../helpers';
import * as sheetModuleApi from '../../../services/sheetModule';
import { getUserById } from '../../users/redux/users.selectors';
import { State } from '../../../../contracts/state.types';
import { CharacterSheet } from '../../../../contracts/features/character-editor.types';
import { getMyCharacters } from '../../my-characters/redux/my-characters.actions';
import * as searchActions from '../../search/redux/search.actions';

import { getSheetOrder, getSheetSelected, getDetails } from './selectors';
import { addSheet, setupBaseCharacter, sheetReOrder, updateCharacterSheetDataUser } from './character-editor.slice';

export const getCharacterThunk = createAsyncThunk(GET_CHARACTER, (id: string) => {
  return characterApi.getCharacter(id);
});

export const saveCharacterThunk = createAsyncThunk(SAVE_CHARACTER, (data: CharacterSheet) => {
  return characterApi.saveCharacter(data);
});

export const createCharacterThunk = createAsyncThunk(CREATE_CHARACTER, (data: CharacterSheet) => {
  return characterApi.createCharacter(data);
});

export const deleteCharacterThunk = createAsyncThunk(DELETE_CHARACTER, (id: string) => {
  return characterApi.deleteCharacter(id);
});

export const getCharacter =
  (id: string): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch): Promise<void> => {
    await dispatch(getCharacterThunk(id));
  };

const duplicationChecker = (sheetOrder: Array<{ sheetId: string }>, newSheet: string): boolean => {
  if (!sheetOrder.length) {
    return false;
  }

  const data = sheetOrder.find((sheet) => sheet.sheetId === newSheet);

  return Boolean(data);
};

export const addSheetAction =
  (): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch, getState): Promise<void> => {
    const state = getState();

    const selectedSheet = getSheetSelected(state);

    if (!selectedSheet.value || !selectedSheet.label) {
      return;
    }

    const sheetOrder = getSheetOrder(state);

    const position = sheetOrder.length;

    const newSheetKey = generateSheetKey(sheetOrder);

    const sheetId = selectedSheet.value;

    const newItem = { sheetId, position, sheetKey: newSheetKey };

    const newSheetOrder = [...sheetOrder, newItem];

    const duplicationCheck = duplicationChecker(sheetOrder, sheetId);

    if (!duplicationCheck) {
      const sheetModulesData = await sheetModuleApi.getById(sheetId);
      dispatch(addSheet({ data: sheetModulesData }));
    }
    dispatch(sheetReOrder(newSheetOrder));
  };

export const updateUser =
  (selected: { value: string; label: string } | null | undefined): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch, getState): Promise<void> => {
    const state = getState();

    if (selected) {
      const userData = getUserById(state, selected.value);

      dispatch(updateCharacterSheetDataUser(userData));
    }
  };

type SaveCharacterData = {
  details: CharacterSheet;
  displayName: string;
  hours: string;
  changeMessage: string;
};

export const saveCharacter =
  ({ details, displayName, hours, changeMessage }: SaveCharacterData): ThunkAction<void, State, unknown, AnyAction> =>
  async (dispatch): Promise<void> => {
    if (!changeMessage) {
      toast.error('Change message must exist');
      console.error('Change message must exist');
      return;
    }

    const { sheetmodules, ...filteredDetails } = details;

    const fixedHours = parseInt(hours, 10);

    const hoursAfter = details.unspentXp + fixedHours;

    const changeLogMessage = {
      by: displayName,
      hoursAfter,
      change: changeMessage,
      hoursChanged: fixedHours || 0,
      date: dayjs().format('DD/MM/YY'),
    };

    const updatedDetails = {
      ...filteredDetails,
      unspentXp: hoursAfter,
      changeLog: [changeLogMessage, ...details.changeLog],
    };

    try {
      await dispatch(saveCharacterThunk(updatedDetails));
      toast.success('Character Changes Saved');
    } catch (err) {
      console.error('Error happened when saving Character Changes');
      console.log('Character - error', err);
      toast.error('Error happened when saving Character Changes');
    }
  };

const addSheetsForBase =
  (sheetNumber: number): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch, getState): Promise<void> => {
    const state = getState();

    const sheet = defaultSheets[sheetNumber];

    const sheetOrder = getSheetOrder(state);

    const newSheetKey = generateSheetKey(sheetOrder);

    const sheetId = sheet;

    const newItem = { sheetId, position: sheetNumber, sheetKey: newSheetKey };

    const newSheetOrder = [...sheetOrder, newItem];

    const duplicationCheck = duplicationChecker(sheetOrder, sheetId);

    if (!duplicationCheck) {
      const sheetModulesData = await sheetModuleApi.getById(sheetId);

      dispatch(addSheet({ data: sheetModulesData }));
    }
    dispatch(sheetReOrder(newSheetOrder));

    if (sheetNumber === 0) {
      dispatch(addSheetsForBase(1));
    }
  };

export const setUpBaseCharacter =
  (): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch): Promise<void> => {
    dispatch(setupBaseCharacter({}));

    await dispatch(addSheetsForBase(0));
  };

export const createCharacter =
  (): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch, getState): Promise<void> => {
    const state = getState();
    const data = getDetails(state);

    const userState = getState().authentication.user;

    const firstName = userState?.firstName || '';
    const lastName = userState?.lastName || '';

    const changeLogMessage = {
      by: `${firstName} ${lastName}`,
      hoursAfter: 0,
      change: 'Character Created',
      hoursChanged: 0,
      date: dayjs().format('DD/MM/YY'),
    };

    const updatedData = { ...data, changeLog: [changeLogMessage] };

    await dispatch(createCharacterThunk(updatedData));
  };

export const deleteCharacter =
  (id: string, type: string, userId?: string): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch): Promise<void> => {
    await dispatch(deleteCharacterThunk(id));

    if (type === MY_CHARACTERS && userId) {
      await dispatch(getMyCharacters(userId));
    }

    if (type === SEARCH_CHARACTERS) {
      await dispatch(searchActions.getAllCharacters());
    }
  };
