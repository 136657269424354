import React, { lazy } from 'react';

import { PERMISSION_TYPES } from '../features/roles/roles.constants';

import * as routes from './routes.constants';
// import BlankPage from '../components/blankPage';

const Login = lazy(() => import(/* webpackChunkName: "login" */ '../features/authentication/components/login'));
const PasswordFirstTime = lazy(
  () => import(/* webpackChunkName: "passwordFirstTime" */ '../features/authentication/components/password-first-time')
);
const ForgotPassword = lazy(
  () => import(/* webpackChunkName: "forgotPassword" */ '../features/authentication/components/forgot-password')
);
const CharacterView = lazy(
  () => import(/* webpackChunkName: "characterView" */ '../features/character-view/character-view.container')
);
const MyCharacters = lazy(
  () => import(/* webpackChunkName: "myCharacters" */ '../features/my-characters/my-characters.container')
);
const Search = lazy(() => import(/* webpackChunkName: "search" */ '../features/search/search.container'));
const CharacterNew = lazy(
  () => import(/* webpackChunkName: "characterNew" */ '../features/character-editor/character-creator')
);
const CharacterEdit = lazy(
  () => import(/* webpackChunkName: "characterEdit" */ '../features/character-editor/characterEditor')
);
const UsersEdit = lazy(() => import(/* webpackChunkName: "usersEdit" */ '../features/users/components/users-edit'));
const Spellbook = lazy(() => import(/* webpackChunkName: "spellbook" */ '../features/spellbook/spellbook.container'));
const Settings = lazy(() => import(/* webpackChunkName: "settings" */ '../features/settings/settings.container'));
const RolesList = lazy(() => import(/* webpackChunkName: "rolesList" */ '../features/roles/rolesList/roles-list'));
const RoleEditContainer = lazy(
  () => import(/* webpackChunkName: "roleEditContainer" */ '../features/roles/role-edit/role-edit.container')
);
const CharacterSheetBuilderListContainer = lazy(
  () =>
    import(
      /* webpackChunkName: "characterSheetBuilderListContainer" */ '../features/sheet-module-list/sheet-module-list.container'
    )
);
const SheetModuleBuilderContainer = lazy(
  () =>
    import(
      /* webpackChunkName: "SheetModuleBuilderContainer" */ '../features/sheet-module-builder/sheet-module-builder.container'
    )
);
const StatLine = lazy(() => import(/* webpackChunkName: "StatLine" */ '../features/stat-line/stat-line'));
const Experience = lazy(
  () => import(/* webpackChunkName: "Experience" */ '../features/experience/experience.container')
);

const Tags = lazy(() => import(/* webpackChunkName: "Tags" */ '../features/tag/tags.container'));
const TagEditor = lazy(
  () => import(/* webpackChunkName: "Tag-editor" */ '../features/tag/components/editor/editor-container')
);

// FYI ROUTE ORDER MATTERS
export const SIGNED_IN_ROUTES = [
  {
    path: routes.SYSTEM,
    component: <MyCharacters />,
    exact: true,
  },
  {
    path: routes.CHARACTER_VIEW,
    component: <CharacterView />,
    exact: true,
  },
  {
    path: routes.CHARACTER_EDIT,
    component: <CharacterEdit />,
    exact: true,
    permission: PERMISSION_TYPES.character.editView,
  },
  {
    path: routes.CHARACTER_SEARCH,
    component: <Search />,
    exact: true,
    permission: PERMISSION_TYPES.search.view,
  },
  {
    path: routes.CHARACTER_NEW,
    component: <CharacterNew />,
    exact: true,
    permission: PERMISSION_TYPES.character.create,
  },
  {
    path: routes.CHARACTER_EDIT,
    component: <CharacterEdit />,
    exact: true,
    permission: PERMISSION_TYPES.character.editView,
  },
  {
    path: routes.USER_BASE,
    component: <UsersEdit />,
    exact: true,
    permission: PERMISSION_TYPES.users.view,
  },
  {
    path: routes.ROLE_LIST,
    component: <RolesList />,
    exact: true,
    permission: PERMISSION_TYPES.role.update,
  },
  {
    path: routes.ROLE_EDIT,
    component: <RoleEditContainer />,
    exact: true,
    permission: PERMISSION_TYPES.role.update,
  },
  {
    path: routes.CHARACTER_SHEET_BUILDER_LIST,
    component: <CharacterSheetBuilderListContainer />,
    exact: true,
    permission: PERMISSION_TYPES.role.update,
  },
  {
    path: routes.CHARACTER_SHEET_BUILDER_EDIT,
    component: <SheetModuleBuilderContainer />,
    exact: true,
    permission: PERMISSION_TYPES.role.update,
  },
  {
    path: routes.CHARACTER_EXPERIENCE,
    component: <Experience />,
    exact: true,
    permission: PERMISSION_TYPES.character.update,
  },
  {
    path: routes.TAGS,
    component: <Tags />,
    exact: true,
    permission: PERMISSION_TYPES.tags.view,
  },
  {
    path: routes.TAGS_EDIT,
    component: <TagEditor />,
    exact: true,
    permission: PERMISSION_TYPES.tags.update,
  },
  {
    path: routes.SETTINGS_BASE,
    component: <Settings />,
    exact: true,
  },
  {
    path: routes.SPELLS,
    component: <Spellbook />,
    exact: true,
  },
  {
    path: routes.STATS,
    component: <StatLine />,
    exact: true,
  },
  {
    path: routes.SYSTEM,
    from: routes.BASE_REDIRECT,
    redirect: true,
  },
];

export const FIRST_TIME_ROUTES = [
  {
    path: routes.ONE_TIME_PASSWORD_UPDATE,
    component: <PasswordFirstTime />,
    exact: true,
  },
  {
    path: routes.ONE_TIME_PASSWORD_UPDATE,
    from: routes.BASE_REDIRECT,
    redirect: true,
  },
];

export const SIGNED_OUT_ROUTES = [
  {
    path: routes.LOGIN,
    component: <Login />,
    exact: true,
  },
  {
    path: routes.FORGOT_PASSWORD,
    component: <ForgotPassword />,
    exact: true,
  },
  {
    path: routes.ONE_TIME_PASSWORD_UPDATE,
    component: <PasswordFirstTime />,
    exact: true,
  },
  {
    path: routes.CHARACTER_VIEW,
    component: <CharacterView />,
    exact: true,
  },
  {
    path: routes.SPELLS,
    component: <Spellbook />,
    exact: true,
  },
  {
    path: routes.STATS,
    component: <StatLine />,
    exact: true,
  },
  {
    path: routes.LOGIN,
    from: routes.BASE_REDIRECT,
    redirect: true,
  },
];
