import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import NotAuthorised from '../../components/not-authorised';
import { RootState } from '../../store/store';

import checkPermissions from './checkPermissions';

type AuthorizationCheckProps = {
  children: JSX.Element;
  permission: string;
};

const AuthorizationCheck = ({ children, permission }: AuthorizationCheckProps): JSX.Element => {
  const userHasPermissions = useSelector((state: RootState) => checkPermissions(state, permission));
  if (userHasPermissions) {
    return children;
  } else {
    return <NotAuthorised />;
  }
};

AuthorizationCheck.propTypes = {
  children: PropTypes.node.isRequired,
  permission: PropTypes.string.isRequired,
};

export default AuthorizationCheck;
