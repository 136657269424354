// eslint-disable-next-line import/no-named-as-default
import createCachedSelector, { LruObjectCache } from 're-reselect';
import _get from 'lodash/get';

import { RootState } from '../../store/store';
import { AuthenticationRoles } from '../../../contracts/common/common.types';

import { userPermissionsSelector } from './redux/authentication.selectors';

const permissionSelector = (_state: RootState, permission: string): string => permission;

const permissionCalculator = (userPermissions: AuthenticationRoles[] = [], permission: string): boolean => {
  return userPermissions.reduce((acc, role) => {
    if (_get(role, [permission], false)) {
      return true;
    }
    return acc;
  }, false);
};

const permissionCheck = createCachedSelector(
  userPermissionsSelector,
  permissionSelector,
  (userPermissions, permission) => permissionCalculator(userPermissions, permission)
)({
  keySelector: (_state: RootState, permission) => `${permission}`,
  cacheObject: new LruObjectCache({ cacheSize: 6 }),
});

export default function checkPermissions(state?: RootState, permission?: string): boolean {
  if (!permission || !state) {
    return false;
  }

  return permissionCheck(state, permission);
}
