import { createSlice } from '@reduxjs/toolkit';

import { ExperienceStore } from '../../../../contracts/features/experience.types';

import { submitThunk } from './experience.actions';
import {
  resetExperienceReducer,
  addCharacterReducer,
  updateCharacterReducer,
  removeCharacterReducer,
  updateMessageReducer,
  submitFulfilledReducer,
  submitRejectedReducer,
  restoreFromStoreReducer,
} from './experience.reducer';

export const initialState: ExperienceStore = {
  selectedCharacters: [],
  message: '',
};

const experienceSlice = createSlice({
  name: 'experience',
  initialState,
  reducers: {
    resetExperience(state) {
      resetExperienceReducer(state, { type: '' });
    },
    addCharacter(state, action) {
      addCharacterReducer(state, action);
    },
    updateCharacter(state, action) {
      updateCharacterReducer(state, action);
    },
    removeCharacter(state, action) {
      removeCharacterReducer(state, action);
    },
    updateMessage(state, action) {
      updateMessageReducer(state, action);
    },
    restoreFromStore(state) {
      restoreFromStoreReducer(state, { type: '' });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(submitThunk.fulfilled, submitFulfilledReducer).addCase(submitThunk.rejected, submitRejectedReducer);
  },
});

export const { addCharacter, updateCharacter, removeCharacter, resetExperience, updateMessage, restoreFromStore } =
  experienceSlice.actions;

export default experienceSlice.reducer;
