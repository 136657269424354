import { ICellData, ISheetLayout } from '../types';

export const defaultCell = {
  cellId: '',
  width: 1,
  height: 1,
  style: 'standard',
  type: 'blank',
  value: '',
  alignment: 'center',
  checked: false,
};

const COLUMN_LENGTH = 36;

const ROW_LENGTH = 37;

function landscapeGridLayout(): ISheetLayout[] {
  function generateColumns(): ICellData[] {
    const columns = [];

    for (let i = 1; i < COLUMN_LENGTH + 1; i++) {
      columns.push({ columnPosition: i, ...defaultCell });
    }

    return columns;
  }

  function generateRows(): ISheetLayout[] {
    const rows = [];

    for (let i = 1; i < ROW_LENGTH + 1; i++) {
      rows.push({
        rowPosition: i,
        columns: generateColumns(),
      });
    }

    return rows;
  }

  return generateRows();
}

export default landscapeGridLayout();
