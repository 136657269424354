import axios from 'axios';
import { isImmutable } from 'immutable';

const system = 'seaxe';

export const baseUrl = `/${system}`;

const url = `${baseUrl}/api/v2/users`;

export const getAllUsers = () => {
  return axios.get(`${url}`).then((response) => response.data);
};

const getPayload = (data) => {
  if (isImmutable(data)) {
    return data.toJS();
  }
  return data;
};

export const createUserApi = (data) => {
  const payload = getPayload(data);

  const { email, firstName, lastName } = payload;

  return axios.post(`${url}`, { email, firstName, lastName }).then((response) => {
    return response.data;
  });
};
