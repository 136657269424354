import axios from 'axios';

import { baseUrl } from '../constants';
import { TagList, TagPayload } from '../../contracts/features/tags.types';

const system = 'seaxe';

const url = `${baseUrl}/${system}/api/v2/tags`;

export const getTagsBySystem = (): Promise<TagList[]> =>
  axios.get<{ tags: TagList[] }>(`${url}`).then((response) => response.data.tags);

export const getTagsBySystemDetailed = (): Promise<TagList[]> =>
  axios.get<{ tags: TagList[] }>(`${url}/detailed`).then((response) => response.data.tags);

export const createNewTag = (tag: string): Promise<TagList> => {
  const payload = { name: tag };
  return axios.post(`${url}`, payload).then((response) => response.data.tags);
};

export const getTagById = async (id: string): Promise<TagList> => {
  return axios.get<{ tag: TagList }>(`${url}/${id}`).then((response) => response.data.tag);
};

export const updateTagById = async (payload: TagPayload): Promise<TagList> => {
  return axios.put<{ tag: TagList }>(`${url}/${payload.id}`, payload).then((response) => response.data.tag);
};

export const deleteTagById = async (id: string): Promise<TagList> => {
  return axios.delete<{ tag: TagList }>(`${url}/${id}`).then((response) => response.data.tag);
};
