import { CaseReducer, PayloadAction, SerializedError } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import { SheetModuleList, SheetModuleState } from '../../../../contracts/features/sheet-modules.types';

export const sheetModulesGetFulfilledReducer: CaseReducer<
  SheetModuleState,
  PayloadAction<SheetModuleList[], string, { requestStatus: 'fulfilled' }, never>
> = (state, action) => {
  const { payload } = action;
  state.sheetModuleList = payload;
  state.count = payload.length;
  state.loading = false;
};

export const sheetModulesGetRejectedReducer: CaseReducer<
  SheetModuleState,
  PayloadAction<unknown, string, unknown, SerializedError>
> = (state, action) => {
  const { error } = action;
  console.log('sheetModulesGetRejectedReducer - error', error.message);
  toast.error('Failed to Sheet Modules');
  state.loading = false;
};

export const teardownReducer: CaseReducer<SheetModuleState> = (state): void => {
  state.sheetModuleList = [];
  state.newSheetModuleName = '';
  state.loading = false;
  state.error = '';
  state.newSheetModuleError = '';
  state.count = 0;
};

export const sheetModulesCreateFulfilledReducer: CaseReducer<
  SheetModuleState,
  PayloadAction<{ data: SheetModuleList[] }, string, { requestStatus: 'fulfilled' }, never>
> = (state, action) => {
  const { payload } = action;
  state.sheetModuleList = payload.data;
  state.count = payload.data.length;
  state.loading = false;

  toast.success('New Sheet Module Created');
};

export const sheetModulesCreateRejectedReducer: CaseReducer<
  SheetModuleState,
  PayloadAction<unknown, string, unknown, SerializedError>
> = (state, action) => {
  const { error } = action;
  console.log('sheetModulesCreateRejectedReducer - error', error.message);

  toast.error('Failed to create new Sheet Module');

  state.loading = false;
};
