/* Router Help:
:id - matches params and adds it to match prop with withRouter HOC
:id+ - matches multiple until next slash
e.g. /page/:slug/edit - /page/test/page/slug/edit would not match
    /page/:slug+/edit - /page/test/page/slug/edit would match

 https://github.com/ReactTraining/react-router/blob/master/packages/react-router/docs/api/Route.md#path-string--string
 */

export const LOGOUT = '/logout';
export const LOGIN = '/login';
export const FORGOT_PASSWORD = '/forgot-password';
export const ONE_TIME_PASSWORD_UPDATE = '/onetime-password-update';

export const BASE_ROUTE = '/';
export const BASE_REDIRECT = '/*';

export const HOME = '/home';

export const SYSTEM = `/seaxe`;
export const SYSTEM_REDIRECT = `/seaxe/*`;

export const CHARACTER_NEW = `${SYSTEM}/character/new`;
export const CHARACTER_VIEW = `${SYSTEM}/character/:id/view`;
export const CHARACTER_EDIT = `${SYSTEM}/character/:id/edit`;
export const CHARACTER_SEARCH = `${SYSTEM}/search`;

export const SPELLS = `/spells`;

export const STATS = `/stats`;

export const SETTINGS_BASE = `/settings`;

export const USER_BASE = `${SYSTEM}/users`;

export const ROLE_BASE = `${SYSTEM}/roles`;
export const ROLE_LIST = `${ROLE_BASE}/list`;
export const ROLE_EDIT = `${ROLE_BASE}/edit/:id`;

export const CHARACTER_SHEET_BUILDER_BASE = `${SYSTEM}/characterSheetsBuilder`;
export const CHARACTER_SHEET_BUILDER_LIST = `${CHARACTER_SHEET_BUILDER_BASE}/list`;
export const CHARACTER_SHEET_BUILDER_EDIT = `${CHARACTER_SHEET_BUILDER_BASE}/edit/:id`;

export const CHARACTER_EXPERIENCE = `${SYSTEM}/experience`;

export const TAGS = `${SYSTEM}/tags`;
export const TAGS_EDIT = `${SYSTEM}/tags/edit/:id`;
