import { RootState } from '../../../store/store';
import { AuthenticationRoles } from '../../../../contracts/common/common.types';
import { AuthenticationConfig } from '../../../../contracts/features/authentication.types';

export const sliceState = (state: RootState): AuthenticationConfig => state.authentication;

export const getFirstName = (state: RootState): string => sliceState(state).user?.firstName || '';
export const getLastName = (state: RootState): string => sliceState(state).user?.lastName || '';
export const getDisplayName = (state: RootState): string => {
  return `${getFirstName(state)} ${getLastName(state)}`;
};
export const getIdFromState = (state: RootState): string => sliceState(state).user?.id || '';
export const getUserFirstTime = (state: RootState): boolean => sliceState(state).user?.firstTime || false;
export const getInitialAuthCheck = (state: RootState): boolean => sliceState(state).initialAuthCheck;
export const getUserLoggedInStatus = (state: RootState): boolean => sliceState(state).loggedIn;

export const getAuthError = (state: RootState): string => sliceState(state).error || '';

export const userPermissionsSelector = (state: RootState): AuthenticationRoles[] => sliceState(state).roles || [];
