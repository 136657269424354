import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkAction } from 'redux-thunk';
import { AnyAction } from 'redux';
import { History } from 'history';

import { authCheck, userLogout } from '../../../services/login';
import { RETRY_LIMIT } from '../authentication.constants';
import { LoginUser } from '../../../../contracts/features/authentication.types';
import { resetPassword, updatePasswordApi, updateUserApi } from '../../../services/users';
import { LOGIN } from '../../../routes/routes.constants';
import { RootState } from '../../../store/store';
import { User } from '../../../../contracts/common/common.types';

import { retryUserLogin } from './authentication.helpers';

export const loginUser = createAsyncThunk('authentication/login-user', (credentials: LoginUser) => {
  return retryUserLogin(credentials, RETRY_LIMIT);
});

export const authenticationCheck = createAsyncThunk('authentication/check', () => {
  return authCheck();
});
export const initialAuthenticationCheck = createAsyncThunk('initialAuthentication/check', () => {
  return authCheck();
});

export const logoutUser = createAsyncThunk('authentication/logout-user', () => {
  return userLogout();
});

export const resetUserPassword = createAsyncThunk('authentication/reset-password', (email: string) => {
  return resetPassword(email);
});

export const updateUserAction = createAsyncThunk('user/updateUser', (userDetails: User) => {
  return updateUserApi(userDetails);
});

type UpdatePasswordApiArgs = {
  password: string;
  newPassword: string;
  username: string;
  id: string;
};

export const updateUserPasswordAction = createAsyncThunk(
  'user/updateUser',
  ({ password, newPassword, username, id }: UpdatePasswordApiArgs) => {
    return updatePasswordApi({ password, newPassword, username, id });
  }
);

export const initialAuthCheck =
  (): ThunkAction<void, RootState, undefined, AnyAction> =>
  async (dispatch): Promise<void> => {
    await dispatch(initialAuthenticationCheck());
  };

export const login =
  (user: string, password: string): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch): Promise<void> => {
    await dispatch(loginUser({ user, password }));
    await dispatch(authenticationCheck());
  };

export const logout =
  (): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch): Promise<void> => {
    await dispatch(logoutUser());
  };

export const resetUserPasswordFlow =
  (setLocation: (url: string) => void, email: string): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch): Promise<void> => {
    await dispatch(resetUserPassword(email));
    setLocation(LOGIN);
  };

export const updateUser =
  (): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch, getState): Promise<void> => {
    const userDetails = getState().authentication.userDetails;

    await dispatch(updateUserAction(userDetails));
  };

type UpdatePasswordArgs = {
  password: string;
  newPassword: string;
};

export const updateUserPassword =
  ({ password, newPassword }: UpdatePasswordArgs): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch, getState): Promise<void> => {
    const state = getState();
    const username = state.authentication.user.username;
    const id = state.authentication.user.id;

    await dispatch(updateUserPasswordAction({ password, newPassword, username, id }));
    await dispatch(logoutUser());
  };
