import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';

import { CharacterList, MyCharacterConfig } from '../../../../contracts/features/my-characters.types';

export const getMyCharactersFulfilledReducer: CaseReducer<MyCharacterConfig, PayloadAction<CharacterList[]>> = (
  state,
  action
) => {
  const { payload: characterList } = action;

  state.characterList = characterList.map((character) => ({ ...character, lastModified: character.updated_at }));
};
