import { CaseReducer, PayloadAction, SerializedError } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import { GetCharacterResponse } from '../../../../contracts/features/character-editor.types';
import { CharacterViewState } from '../../../../contracts/features/characterView.types';

export const characterGetFulfilledReducer: CaseReducer<
  CharacterViewState,
  PayloadAction<GetCharacterResponse, string, { arg: string; requestId: string; requestStatus: 'fulfilled' }, never>
> = (state, action) => {
  const { payload } = action;
  state.details = payload;
};

export const characterGetRejectedReducer: CaseReducer<
  CharacterViewState,
  PayloadAction<unknown, string, unknown, SerializedError>
> = (_state, action) => {
  const { error } = action;
  console.error('authenticationCheckRejectedReducer - error', error.message);
  toast.error('Failed to fetch character');
};

export const resetCharacterReducer: CaseReducer<CharacterViewState> = (state): void => {
  state.details = {
    lastName: '',
    npc: false,
    retired: false,
    unspentXp: 0,
    displayName: '',
    user_id: '',
    system: '',
    characterName: '',
    sheetType: '',
    id: '',
    firstName: '',
    notes: '',
    totalXp: 0, // might not be needed
    customPlayerName: '',
    sheetmodules: [],
    sheetOrder: [],
    changeLog: [],
    tags: [],
    dynamicTags: [],
    customGlobals: {},
    sheetData: [],
  };
  state.error = '';
};
