import { createSelector } from 'reselect';
import _sortBy from 'lodash/sortBy';

import { RootState } from '../../../store/store';
import { User, UserDetails } from '../../../../contracts/features/users.types';

export const usersList = (state: RootState): User[] => state?.users?.list;

export const usersListSelector = createSelector(usersList, (users) => {
  const sortedUsers = _sortBy(users, ['displayName']);

  return sortedUsers.map((user) => ({ label: user.displayName, value: user.id }));
});

export const userDetails = (state: RootState): UserDetails => {
  const { list, ...userInfo } = state.users;
  return userInfo;
};

export const userRoles = (state: RootState): string[] => state?.users?.roles || [];

export const getUserById = (state: RootState, id: string): User | undefined => {
  const list = usersList(state);
  return list.find((user) => user.id === id);
};
