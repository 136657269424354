import axios from 'axios';

import { baseUrl } from '../constants';

const url = `${baseUrl}/api/v2/sheet-module`;

export const getById = (id) => axios.get(`${url}/${id}`).then((response) => response.data.data);

export const getAll = () => axios.get(`${url}`).then((response) => response.data.data);

export const getCoreModules = () => axios.get(`${url}/core-modules`).then((response) => response.data);

export const save = (id, data) => axios.put(`${url}/${id}`, data).then((response) => response.data);

export const create = (name) => axios.post(`${url}`, { name }).then((response) => response.data);
