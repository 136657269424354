import React, { JSX, ReactNode, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'wouter';

import * as routes from '../../routes/routes.constants';
import AuthorizationCheckNull from '../authentication/authorizationCheckNull';
import { PERMISSION_TYPES } from '../roles/roles.constants';
import { logout as authLogout } from '../authentication/redux/authentication.actions';

import { isNavigationOpen } from './redux/navigation.selectors';
import { toggleMenu } from './redux/navigation.slice';
import * as CSS from './navigation.styles';

const Navigation = ({ children }: { children: ReactNode }): JSX.Element => {
  const dispatch = useDispatch();
  const navOpen = useSelector(isNavigationOpen);
  const [location] = useLocation();

  const previewRegex = /\/([a-zA-Z0-9]*)\/character\/([a-zA-Z0-9-]*)\/view/;

  const isPreviewUrl = previewRegex.test(location);

  function changeMenuState(): void {
    dispatch(toggleMenu());
  }

  function logout(): void {
    dispatch(authLogout());
  }

  return (
    <Fragment>
      <CSS.NavigationBar isPreviewUrl={isPreviewUrl}>
        <CSS.AlignLeft>
          {/* <Link className={ 'nav-btn navigation-bar_system' } href="/home">*/}
          <CSS.NavBtn>
            <CSS.Logo>
              <CSS.LogoMainColour>NC</CSS.LogoMainColour>
              <CSS.LogoAltColour>row</CSS.LogoAltColour>
            </CSS.Logo>
            {/* </Link>*/}
          </CSS.NavBtn>
          <CSS.NavSystem>
            <CSS.System>Seaxe</CSS.System>
          </CSS.NavSystem>
        </CSS.AlignLeft>
        <CSS.MobileContainer navOpen={navOpen}>
          <CSS.MenuBreak />
          <CSS.Center>
            <CSS.NavItem href={`~${routes.SYSTEM}`}>My Characters</CSS.NavItem>
            <AuthorizationCheckNull permission={PERMISSION_TYPES.search.view}>
              <CSS.NavItem href={`~${routes.CHARACTER_SEARCH}`}>Search</CSS.NavItem>
            </AuthorizationCheckNull>
            <AuthorizationCheckNull permission={PERMISSION_TYPES.character.create}>
              <CSS.NavItem href={`~${routes.CHARACTER_NEW}`}>New Character</CSS.NavItem>
            </AuthorizationCheckNull>
            <AuthorizationCheckNull permission={PERMISSION_TYPES.tags.view}>
              <CSS.NavItem href={`~${routes.TAGS}`}>Tags</CSS.NavItem>
            </AuthorizationCheckNull>
            <AuthorizationCheckNull permission={PERMISSION_TYPES.users.update}>
              <CSS.NavItem href={`~${routes.USER_BASE}`}>Users</CSS.NavItem>
            </AuthorizationCheckNull>
            <AuthorizationCheckNull permission={PERMISSION_TYPES.users.update}>
              <CSS.NavItem href={`~${routes.CHARACTER_EXPERIENCE}`}>Experience</CSS.NavItem>
            </AuthorizationCheckNull>
            <AuthorizationCheckNull permission={PERMISSION_TYPES.role.update}>
              <CSS.NavItem href={`~${routes.ROLE_LIST}`}>Roles</CSS.NavItem>
            </AuthorizationCheckNull>
            <AuthorizationCheckNull permission={PERMISSION_TYPES.sheetModules.view}>
              <CSS.NavItem href={`~${routes.CHARACTER_SHEET_BUILDER_LIST}`}>Sheet Modules Builder</CSS.NavItem>
            </AuthorizationCheckNull>
          </CSS.Center>
          <CSS.MenuBreak />
        </CSS.MobileContainer>
        <CSS.SettingsWrapper navOpen={navOpen}>
          <CSS.NavItem href={`~${routes.SETTINGS_BASE}`}>Settings</CSS.NavItem>
          <CSS.NavButton type="button" data-cy="logout" onClick={logout}>
            Logout
          </CSS.NavButton>
        </CSS.SettingsWrapper>
        <CSS.Menu onClick={changeMenuState}>
          <CSS.IconBar />
          <CSS.IconBar />
          <CSS.IconBar />
        </CSS.Menu>
      </CSS.NavigationBar>
      <CSS.ContentWrapper isPreviewUrl={isPreviewUrl}>{children}</CSS.ContentWrapper>
    </Fragment>
  );
};

export default Navigation;
