import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';

import { StatLineState } from '../../../../contracts/features/stat-line.types';
import * as types from '../constants';
import { getToken, setToken } from '../../../services/localStorage';

export const setNameReducer: CaseReducer<StatLineState, PayloadAction<string>> = (state, action): void => {
  const { payload } = action;

  state.name = payload;
};

export const addStatReducer: CaseReducer<StatLineState> = (state): void => {
  state.stats.push(types.DEFAULT_STAT);
};

export const setValueNameReducer: CaseReducer<StatLineState, PayloadAction<{ location: number; value: string }>> = (
  state,
  action
): void => {
  const { payload } = action;

  const { location, value } = payload;
  state.stats[location].name = value;
};

export const setMaxValueReducer: CaseReducer<StatLineState, PayloadAction<{ location: number; value: number }>> = (
  state,
  action
): void => {
  const { payload } = action;

  const { location, value } = payload;
  state.stats[location].maxValue = value;
  state.stats[location].currentValue = value;
};

export const addValueReducer: CaseReducer<StatLineState, PayloadAction<number>> = (state, action): void => {
  const { payload } = action;

  const current = state.stats[payload].currentValue;
  const nextValue = current + 1;
  const max = state.stats[payload].maxValue;

  if (nextValue <= max) {
    state.stats[payload].currentValue = nextValue;
  }
};

export const subtractValueReducer: CaseReducer<StatLineState, PayloadAction<number>> = (state, action): void => {
  const { payload } = action;

  const current = state.stats[payload].currentValue;
  const nextValue = current - 1;

  if (nextValue >= 0) {
    state.stats[payload].currentValue = nextValue;
  }
};

export const saveStatReducer: CaseReducer<StatLineState> = (state): void => {
  setToken({ key: 'statLine', data: state });
};

export const loadStatReducer: CaseReducer<StatLineState> = (state): void => {
  const savedData = getToken('statLine');

  state.name = savedData.name;
  state.stats = savedData.stats || [];
};
