import React, { Suspense, JSX } from 'react';
import { Redirect, Route } from 'wouter';

import AuthorizationCheck from '../features/authentication/authorizationCheck';
import Spinner from '../components/loading-spinner/loading-spinner';

type RouteBuilderProps = {
  path: string;
  component?: JSX.Element;
  header?: JSX.Element;
  exact?: boolean;
  redirect?: boolean;
  routes?: RouteBuilderProps[];
  permission?: string;
  from?: string;
};

type NestedRoutesType = {
  path: string;
  header?: JSX.Element;
  routes: RouteBuilderProps[];
  key: string;
  routeProps?: {
    exact?: boolean;
  };
};

const routeBuilder = (routeConfig: RouteBuilderProps[]): JSX.Element[] => {
  const nestedRoutes = ({ key, path, routes, header }: NestedRoutesType): JSX.Element => {
    if (header) {
      return (
        <Route key={key} path={path} nest>
          <div id="wrapper">
            <div id="header-wrapper">{header}</div>
            <div id="page-content-wrapper">
              <Suspense
                fallback={
                  <div>
                    <Spinner />
                  </div>
                }
              >
                {routeBuilder(routes)}
              </Suspense>
            </div>
          </div>
        </Route>
      );
    }
    return (
      <Route key={key} path={path} nest>
        <Suspense
          fallback={
            <div>
              <Spinner />
            </div>
          }
        >
          {routeBuilder(routes)}
        </Suspense>
      </Route>
    );
  };

  return routeConfig.map((route) => {
    const { path, redirect = false, routes = [], permission, component, header, from, ...routeProps } = route;
    const key = `${path}-${redirect ? 'redirect' : 'path'}`;
    if (redirect) {
      return (
        <Route key={key}>
          <Redirect to={path} />
        </Route>
      );
    }
    if (routes.length) {
      return nestedRoutes({ key, path, routes, header, routeProps });
    }
    if (permission && component) {
      return (
        <Route key={key} path={path}>
          <AuthorizationCheck permission={permission}>{component}</AuthorizationCheck>
        </Route>
      );
    }

    return (
      <Route key={key} path={path}>
        {component}
      </Route>
    );
  });
};

export default routeBuilder;
