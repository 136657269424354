import _sortBy from 'lodash/sortBy';

import { SheetOrder } from '../../../contracts/character.types';

export const generateSheetKey = (sheetOrder: Array<SheetOrder>): string | Error => {
  if (sheetOrder.length < 1) {
    return `sheet-1`;
  }

  const sortedData = _sortBy(sheetOrder, 'sheetKey');

  const lastItem = sortedData[sortedData.length - 1];

  const lastItemKey = lastItem.sheetKey;

  const [, index] = lastItemKey.split('-');

  const newKey = `sheet-${parseInt(index, 10) + 1}`;

  const sanity = sheetOrder.findIndex((sheet) => sheet.sheetKey === newKey);

  if (sanity > -1) {
    throw Error('module key error');
  }
  return newKey;
};
