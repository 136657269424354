import { CaseReducer, PayloadAction, SerializedError } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import { Role, RoleEdit, RolesState } from '../../../../contracts/features/roles.types';

export const resetRolesReducer: CaseReducer<RolesState> = (state) => {
  state.error = '';
  state.draggingPermissionId = '';
  state.loading = false;
  state.list = [];
  state.roleEdit = { name: '', permissions: {} };
  state.selectedPermissions = [];
};

type RolesResponse = {
  data: Role[];
};

export const fetchAllRolesFulfilledReducer: CaseReducer<RolesState, PayloadAction<RolesResponse>> = (state, action) => {
  const { payload } = action;

  state.list = payload.data;
  state.loading = false;
};

export const fetchAllRolesRejectedReducer: CaseReducer<
  RolesState,
  PayloadAction<unknown, string, unknown, SerializedError>
> = (state, action) => {
  const { error } = action;
  console.log('fetchAllRolesRejectedReducer - error', error.message);
  state.loading = false;
};

export const fetchRoleFulfilledReducer: CaseReducer<RolesState, PayloadAction<{ data: RoleEdit }>> = (
  state,
  action
) => {
  const { payload } = action;

  state.roleEdit = payload.data;
  state.loading = false;
};

export const fetchRoleRejectedReducer: CaseReducer<
  RolesState,
  PayloadAction<unknown, string, unknown, SerializedError>
> = (state, action) => {
  const { error } = action;
  console.log('fetchAllRolesRejectedReducer - error', error.message);
  state.loading = false;
};

export const createRolesFulfilledReducer: CaseReducer<RolesState, PayloadAction<RolesResponse>> = (state, action) => {
  const { payload } = action;
  toast.success('created new role');
  state.list = payload.data;
};

export const createRolesRejectedReducer: CaseReducer<
  RolesState,
  PayloadAction<unknown, string, unknown, SerializedError>
> = (_state, action) => {
  const { error } = action;
  console.log('createRolesRejectedReducer - error', error.message);

  toast.error('failed to create role');
};

export const nameChangeReducer: CaseReducer<RolesState, PayloadAction<string>> = (state, action) => {
  const { payload } = action;

  state.roleEdit.name = payload;
};

export const updateSelectedPermissionsReducer: CaseReducer<RolesState, PayloadAction<string[]>> = (state, action) => {
  const { payload } = action;

  state.selectedPermissions = payload;
};

export const updateDraggingPermissionIdReducer: CaseReducer<RolesState, PayloadAction<string>> = (state, action) => {
  const { payload } = action;

  state.draggingPermissionId = payload;
};

export const permissionListUpdateReducer: CaseReducer<
  RolesState,
  PayloadAction<{ permission: string; destination: string }>
> = (state, action) => {
  const { payload } = action;

  const { permission, destination } = payload;

  const permissionList = state.roleEdit.permissions || {};
  const selectedPermissions = state.selectedPermissions;
  const selectedPermissionList = selectedPermissions.includes(permission)
    ? selectedPermissions
    : selectedPermissions.push(permission);

  if (Array.isArray(selectedPermissionList) && selectedPermissionList.length) {
    const updatedPermissionsList = selectedPermissionList.reduce((acc, updatedPermission) => {
      return { ...acc, ...{ [updatedPermission]: destination === 'ROLE_PERMISSIONS' } };
    }, permissionList);

    state.roleEdit.permissions = updatedPermissionsList;
  } else {
    state.roleEdit.permissions = { ...permissionList, ...{ [permission]: destination === 'ROLE_PERMISSIONS' } };
  }
};

type UpdateRoleResponse = {
  data: RoleEdit[];
};

export const updateRoleFulfilledReducer: CaseReducer<RolesState, PayloadAction<UpdateRoleResponse>> = (
  state,
  action
) => {
  const { payload } = action;
  toast.success('Update Role Success');

  const [role] = payload.data;

  state.roleEdit = role;
};

export const updateRoleRejectedReducer: CaseReducer<
  RolesState,
  PayloadAction<unknown, string, unknown, SerializedError>
> = (_state, action) => {
  const { error } = action;
  console.log('updateRoleRejectedReducer - error', error.message);

  toast.error('Update Role Failure');
};
