import React from 'react';

const NotAuthorised = (): JSX.Element => (
  <div className="message message--error">
    <h1 className="message__title">Not Authorised</h1>
    <p className="message__body">You do not have the correct permissions to view this page.</p>
  </div>
);

export default NotAuthorised;
