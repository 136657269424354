import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkAction } from 'redux-thunk';
import { AnyAction } from 'redux';

import { GET_SHEET_MODULE_LIST, CREATE_NEW_SHEET_MODULE } from '../sheetModuleList.constants';
import { RootState } from '../../../store/store';
import * as sheetModuleApi from '../../../services/sheetModule';

export const getSheetModulesThunk = createAsyncThunk(GET_SHEET_MODULE_LIST, () => {
  return sheetModuleApi.getAll();
});

export const createSheetModulesThunk = createAsyncThunk(CREATE_NEW_SHEET_MODULE, (name: string) => {
  return sheetModuleApi.create(name);
});

export const getSheetModules =
  (): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch): Promise<void> => {
    await dispatch(getSheetModulesThunk());
  };

export const createSheetModules =
  (name: string): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch): Promise<void> => {
    await dispatch(createSheetModulesThunk(name));
  };
