import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import { Link } from 'wouter';

import { tabletWidth } from '../../../styles/style-constants';

export const ContentWrapper = styled.div<{ isPreviewUrl: boolean }>`
  ${({ isPreviewUrl }): FlattenSimpleInterpolation => {
    if (!isPreviewUrl) {
      return css`
        padding-top: 55px;
      `;
    }
    return css``;
  }}

  position: relative;
  margin: auto;
`;

export const NavigationBar = styled.div<{ isPreviewUrl: boolean }>`
  ${({ isPreviewUrl }): FlattenSimpleInterpolation => {
    if (isPreviewUrl) {
      return css`
        display: none !important;
      `;
    }
    return css``;
  }}

  justify-content: center;
  z-index: 1000;
  background: #2b2b2b;
  width: 100%;
  color: #f5f5f5;
  height: 55px;
  position: fixed;
  @media (min-width: ${tabletWidth}) {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
  }
`;

export const AlignLeft = styled.div`
  display: flex;
`;

export const Center = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  background-color: #2b2b2b;
  @media (min-width: ${tabletWidth}) {
    background-color: #2b2b2b;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
`;

export const NavBtn = styled.div`
  cursor: pointer;
  position: relative;
  align-items: center;
  display: flex;
  font-size: 20px;
  height: 55px;
  padding: 0 10px;

  @media (min-width: ${tabletWidth}) {
    width: initial;
  }
`;

export const NavSystem = styled.div`
  display: flex;
  left: 0;
  position: relative;
  padding: 0 10px;
  font-size: 20px;
  height: 55px;
  align-items: center;
  background-color: #a61f21;
  width: 100px;
`;

export const System = styled.span`
  width: 100%;
  text-align: center;
`;

export const MenuBreak = styled.hr`
  margin: 0;
  border-color: #adadad;
  @media (min-width: ${tabletWidth}) {
    display: none;
  }
`;

export const NavItem = styled(Link)`
  cursor: pointer;
  position: relative;
  border-bottom: 6px solid #2b2b2b;
  border-top: 6px solid #2b2b2b;
  align-items: center;
  display: flex;
  font-size: 20px;
  height: 55px;
  padding: 0 10px;
  width: 100%;
  color: #f5f5f5;
  background-color: rgb(43, 43, 43);

  &.active {
    border-bottom: 6px solid #a61f21;
  }

  &:hover {
    border-bottom: 6px solid #a61f21;
    text-decoration: none;
    color: #f5f5f5;
  }

  @media (min-width: ${tabletWidth}) {
    width: initial;
  }
`;

export const NavButton = styled.button`
  cursor: pointer;
  position: relative;
  border-bottom: 6px solid #2b2b2b;
  border-top: 6px solid #2b2b2b;
  border-left: none;
  border-right: none;
  align-items: center;
  display: flex;
  font-size: 20px;
  height: 55px;
  padding: 0 10px;
  width: 100%;
  color: #f5f5f5;
  background-color: rgb(43, 43, 43);

  &:hover {
    border-bottom: 6px solid #a61f21;
    text-decoration: none;
    color: #f5f5f5;
  }

  @media (min-width: ${tabletWidth}) {
    width: initial;
  }
`;

export const Menu = styled.div`
  height: 50px;
  width: 50px;
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  @media (min-width: ${tabletWidth}) {
    display: none;
  }
`;

export const IconBar = styled.span`
  background-color: #fff;
  display: block;
  width: 22px;
  height: 2px;
  border-radius: 1px;

  & + & {
    margin-top: 4px;
  }
`;

export const Logo = styled.span`
  font-family: Thempo, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif;
  text-align: center;
  font-size: 30px;
`;

export const LogoMainColour = styled.span`
  color: #a61f21;
`;

export const LogoAltColour = styled.span`
  color: #f5f5f5;
`;

type MobileContainerProps = {
  navOpen: boolean;
};

export const MobileContainer = styled.div<MobileContainerProps>`
  ${({ navOpen }): FlattenSimpleInterpolation => {
    if (navOpen) {
      return css`
        display: block;
        @media (min-width: ${tabletWidth}) {
          display: flex;
        }
      `;
    }
    return css`
      display: none;
      @media (min-width: ${tabletWidth}) {
        display: flex;
      }
    `;
  }}
`;

export const SettingsWrapper = styled.div<MobileContainerProps>`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  background-color: rgb(43, 43, 43);
  @media (min-width: ${tabletWidth}) {
    flex-wrap: nowrap;
    width: initial;
  }

  ${({ navOpen }): FlattenSimpleInterpolation => {
    if (navOpen) {
      return css`
        display: block;
        @media (min-width: ${tabletWidth}) {
          display: flex;
        }
      `;
    }
    return css`
      display: none;
      @media (min-width: ${tabletWidth}) {
        display: flex;
      }
    `;
  }}
`;
