import { createSlice } from '@reduxjs/toolkit';

import { StatLineState } from '../../../../contracts/features/stat-line.types';
import { emptyPayload } from '../../../store/helpers';

import {
  addStatReducer,
  addValueReducer,
  loadStatReducer,
  saveStatReducer,
  setMaxValueReducer,
  setNameReducer,
  setValueNameReducer,
  subtractValueReducer,
} from './stat-line.reducer';

export const initialState: StatLineState = {
  name: '',
  stats: [],
};

const statLineSlice = createSlice({
  name: 'statLine',
  initialState,
  reducers: {
    setName(state, action) {
      setNameReducer(state, action);
    },
    addStat(state) {
      addStatReducer(state, emptyPayload);
    },
    setValueName(state, action) {
      setValueNameReducer(state, action);
    },
    setMaxValue(state, action) {
      setMaxValueReducer(state, action);
    },
    addValue(state, action) {
      addValueReducer(state, action);
    },
    subtractValue(state, action) {
      subtractValueReducer(state, action);
    },
    saveStat(state) {
      saveStatReducer(state, emptyPayload);
    },
    loadStat(state) {
      loadStatReducer(state, emptyPayload);
    },
  },
});

export const { setName, addStat, loadStat, saveStat, addValue, setValueName, subtractValue, setMaxValue } =
  statLineSlice.actions;

export default statLineSlice.reducer;
