import fuzzysearch from 'fuzzysearch';

import { SearchCharacter } from '../../../../../contracts/features/search.types';
import * as types from '../../search.constants';
import { TagSelect } from '../../../../../contracts/features/tags.types';

export const calculatePagesCount = (total: number): number => Math.ceil(total / types.CHARACTERS_PER_PAGE);

export const filterData = (filterType: string, data: SearchCharacter[]): SearchCharacter[] => {
  switch (filterType) {
    case 'active':
      return data.filter((char) => !char.retired).filter((char) => !char.npc);
    case 'inactive':
      return data.filter((char) => char.retired).filter((char) => !char.npc);
    case 'npc':
      return data.filter((char) => char.npc);
    default:
      return data;
  }
};

export const searchData = (search: string, searchBy: string, data: SearchCharacter[]): SearchCharacter[] =>
  data.filter((char) => {
    const getPlayerName = (): string => {
      if (char.customPlayerName) {
        return char.customPlayerName;
      }
      return char.displayName;
    };

    const filterValue = searchBy === 'displayName' ? getPlayerName() : char.characterName;

    return fuzzysearch(search.toLowerCase(), filterValue.toLowerCase());
  });

const comparisonSortByCharacterName = (a: SearchCharacter, b: SearchCharacter): number =>
  a.characterName < b.characterName ? -1 : 1;

const comparisonSortByUpdatedAt = (a: SearchCharacter, b: SearchCharacter): number =>
  a.updated_at < b.updated_at ? -1 : 1;

const sortList = (list: SearchCharacter[], sortBy: string): SearchCharacter[] => {
  if (sortBy === 'updated_at') {
    return list.sort(comparisonSortByUpdatedAt);
  }
  return list.sort(comparisonSortByCharacterName);
};
export const sortCharacters = (
  list: SearchCharacter[],
  sortBy = 'characterName',
  direction = 'ascending'
): SearchCharacter[] => {
  const sortedList = sortList(list, sortBy);

  if (direction === 'descending') {
    return [...sortedList].reverse();
  }
  return sortedList;
};

export const filterByTag = (tags: TagSelect[], data: SearchCharacter[], some: boolean): SearchCharacter[] => {
  if (!tags || tags.length < 1) {
    return data;
  }

  if (some) {
    return data.filter((char) =>
      tags.some((tag) => {
        return char.tags.includes(tag.value) || char.dynamicTags?.includes(tag.value);
      })
    );
  } else {
    return data.filter((char) =>
      tags.every((tag) => char.tags.includes(tag.value) || char.dynamicTags?.includes(tag.value))
    );
  }
};
