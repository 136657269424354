import { configureStore } from '@reduxjs/toolkit';

import authenticationReducer from '../features/authentication/redux/authentication.slice';
import myCharactersReducer from '../features/my-characters/redux/my-characters.slice';
import searchReducer from '../features/search/redux/search.slice';
import tagReducer from '../features/tag/redux/tag.slice';
import characterEditorReducer from '../features/character-editor/redux/character-editor.slice';
import sheetModuleReducer from '../features/sheet-module-list/redux/sheet-module.slice';
import usersReducer from '../features/users/redux/users.slice';
import userReducer from '../features/user/redux/user.slice';
import navigationReducer from '../features/navigation/redux/navigation.slice';
import characterViewReducer from '../features/character-view/redux/character-view.slice';
import rolesReducer from '../features/roles/redux/roles.slice';
import experienceReducer from '../features/experience/redux/experience.slice';
import statLineReducer from '../features/stat-line/redux/stat-line.slice';
import sheetModuleBuilderReducer from '../features/sheet-module-builder/redux/sheet-module-builder.slice';

export const reducers = {
  authentication: authenticationReducer,
  characterEditor: characterEditorReducer,
  characterView: characterViewReducer,
  experience: experienceReducer,
  myCharacters: myCharactersReducer,
  navigation: navigationReducer,
  roles: rolesReducer,
  search: searchReducer,
  sheetModules: sheetModuleReducer,
  sheetModuleBuilder: sheetModuleBuilderReducer,
  statLine: statLineReducer,
  tags: tagReducer,
  user: userReducer,
  users: usersReducer,
};

export const store = configureStore({
  reducer: reducers,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
