import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkAction } from 'redux-thunk';
import { AnyAction } from 'redux';

import { GET_SHEET_MODULE, SAVE_SHEET_MODULE } from '../sheet-module-builder.constants';
import * as sheetModuleApi from '../../../services/sheetModule';
import { RootState } from '../../../store/store';
import { ISheetLayout } from '../types';

import { getCustomVariables, getGridLayout, getId } from './sheet-module-builder.selectors';

export const getSheetModuleThunk = createAsyncThunk(GET_SHEET_MODULE, (id: string) => {
  return sheetModuleApi.getById(id);
});

export const saveSheetModuleThunk = createAsyncThunk(
  SAVE_SHEET_MODULE,
  ({ id, newLayout, newCustomVariables }: { id: string; newLayout: ISheetLayout[]; newCustomVariables: string[] }) => {
    return sheetModuleApi.save(id, {
      config: { layout: newLayout, customVariables: newCustomVariables },
    });
  }
);

export const getSheetModule =
  (id: string): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch): Promise<void> => {
    await dispatch(getSheetModuleThunk(id));
  };

export const saveSheetModules =
  (): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch, getState): Promise<void> => {
    const state = getState();

    const id = getId(state);
    const newLayout = getGridLayout(state);
    const newCustomVariables = getCustomVariables(state);

    await dispatch(saveSheetModuleThunk({ id, newLayout, newCustomVariables }));

    dispatch(getSheetModuleThunk(id));
  };
