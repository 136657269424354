import axios from 'axios';

import { baseUrl } from '../constants/index';

const system = 'seaxe';

const url = `${baseUrl}/api/v2/users/auth`;

const urlSystem = `${baseUrl}/${system}/api/v2/users/auth`;

export const authCheck = () => axios.get(`${urlSystem}`).then((response) => response.data);

export const userLogin = ({ user, password }) => {
  const payload = { username: user, password };

  return axios.post(`${url}/login`, payload).then((response) => response.data);
};

export const userLogout = () => axios.get(`${url}/logout`).then((response) => response.data);

export default { userLogin, userLogout };
