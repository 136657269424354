import { ThunkAction } from 'redux-thunk';
import { AnyAction } from 'redux';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { createUserApi } from '../../../services/usersSystem';
import { RootState } from '../../../store/store';
import { fetchListOfUsers } from '../../users/redux/users.actions';

export const createUserAction = createAsyncThunk(
  'user/create-user',
  (data: { firstName: string; lastName: string; email: string }) => {
    return createUserApi(data);
  }
);

export const createUser =
  (data: { firstName: string; lastName: string; email: string }): ThunkAction<void, RootState, null, AnyAction> =>
  async (dispatch): Promise<void> => {
    await dispatch(createUserAction(data));
    await dispatch(fetchListOfUsers());
  };
