import { createSlice } from '@reduxjs/toolkit';

import { CharacterEditorState } from '../../../../contracts/features/character-editor.types';
import { NAME } from '../characterEditor.constants';

import {
  getCharacterThunk,
  saveCharacterThunk,
  createCharacterThunk,
  deleteCharacterThunk,
} from './character-editor.actions';
import {
  characterGetFulfilledReducer,
  characterGetRejectedReducer,
  saveCharacterFulfilledReducer,
  resetCharacterReducer,
  updateCharacterReducer,
  updateCharacterSheetDataReducer,
  updateCharacterSheetDataUserReducer,
  updateCharacterSheetDataWithTagsReducer,
  updateCharacterSheetLinkedDataReducer,
  sheetSelectedReducer,
  addSheetReducer,
  sheetReOrderReducer,
  removeSheetReducer,
  moveItemDownReducer,
  moveItemUpReducer,
  setupBaseCharacterReducer,
  createCharacterFulfilledReducer,
  createCharacterRejectedReducer,
  deleteCharacterFulfilledReducer,
  deleteCharacterRejectedReducer,
} from './character-editor.reducer';

export const initialState: CharacterEditorState = {
  characterSheet: {
    lastName: '',
    npc: false,
    retired: false,
    unspentXp: 0,
    displayName: '',
    user_id: '',
    system: '',
    characterName: '',
    sheetType: '',
    id: '',
    firstName: '',
    notes: '',
    totalXp: 0, // might not be needed
    customPlayerName: '',
    sheetmodules: [],
    sheetOrder: [],
    changeLog: [],
    tags: [],
    dynamicTags: [],
    customGlobals: {},
    sheetData: [],
  },
  options: {
    selected: {
      value: '',
      label: '',
    },
    selectedData: undefined,
    sheetModuleLoading: false,
  },
  formIsHalfFilledOut: false,
  createdNewCharacter: false,
};

const characterEditorSlice = createSlice({
  name: NAME,
  initialState,
  reducers: {
    resetCharacter(state) {
      resetCharacterReducer(state, { type: '' });
    },
    updateCharacter(state, action) {
      updateCharacterReducer(state, action);
    },
    updateCharacterSheetData(state, action) {
      updateCharacterSheetDataReducer(state, action);
    },
    updateCharacterSheetLinkedData(state, action) {
      updateCharacterSheetLinkedDataReducer(state, action);
    },
    updateCharacterSheetDataWithTags(state, action) {
      updateCharacterSheetDataWithTagsReducer(state, action);
    },
    sheetSelected(state, action) {
      sheetSelectedReducer(state, action);
    },
    addSheet(state, action) {
      addSheetReducer(state, action);
    },
    removeSheet(state, action) {
      removeSheetReducer(state, action);
    },
    sheetReOrder(state, action) {
      sheetReOrderReducer(state, action);
    },
    moveItemDown(state, action) {
      moveItemDownReducer(state, action);
    },
    moveItemUp(state, action) {
      moveItemUpReducer(state, action);
    },
    updateCharacterSheetDataUser(state, action) {
      updateCharacterSheetDataUserReducer(state, action);
    },
    setupBaseCharacter(state, action) {
      setupBaseCharacterReducer(state, action);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCharacterThunk.fulfilled, characterGetFulfilledReducer)
      .addCase(getCharacterThunk.rejected, characterGetRejectedReducer);

    builder.addCase(saveCharacterThunk.fulfilled, saveCharacterFulfilledReducer);

    builder
      .addCase(createCharacterThunk.fulfilled, createCharacterFulfilledReducer)
      .addCase(createCharacterThunk.rejected, createCharacterRejectedReducer);
    builder
      .addCase(deleteCharacterThunk.fulfilled, deleteCharacterFulfilledReducer)
      .addCase(deleteCharacterThunk.rejected, deleteCharacterRejectedReducer);
  },
});

export const {
  resetCharacter,
  updateCharacter,
  updateCharacterSheetData,
  updateCharacterSheetLinkedData,
  updateCharacterSheetDataWithTags,
  sheetSelected,
  addSheet,
  sheetReOrder,
  removeSheet,
  moveItemDown,
  moveItemUp,
  updateCharacterSheetDataUser,
  setupBaseCharacter,
} = characterEditorSlice.actions;

export default characterEditorSlice.reducer;
