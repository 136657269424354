import { ThunkAction } from 'redux-thunk';
import { AnyAction } from 'redux';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { getAllUsers } from '../../../services/usersSystem';
import { RootState } from '../../../store/store';
import { getUserById, updateUserRolesApi, deleteUserByIdApi } from '../../../services/users';
import { UserDetails } from '../../../../contracts/features/users.types';

export const fetchListOfUsers = createAsyncThunk('users/fetch-all-users', () => {
  return getAllUsers();
});

export const fetchUserById = createAsyncThunk('users/fetch-single-users', (id: string) => {
  return getUserById(id);
});

export const updateSingleUser = createAsyncThunk('users/update-single-users', (user: UserDetails) => {
  return updateUserRolesApi(user);
});

export const deleteUserById = createAsyncThunk('users/delete-users', (id: string) => {
  return deleteUserByIdApi(id);
});

export const getListOfUsers =
  (): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch): Promise<void> => {
    await dispatch(fetchListOfUsers());
  };

export const getSingleUser =
  (id: string): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch): Promise<void> => {
    await dispatch(fetchUserById(id));
  };

export const updateUser =
  (): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch, getState): Promise<void> => {
    const state = getState();
    const { list, ...user } = state.users;

    await dispatch(updateSingleUser(user));
  };

export const deleteUser =
  (id: string): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch): Promise<void> => {
    await dispatch(deleteUserById(id));
    await dispatch(fetchListOfUsers());
  };
