import React, { ComponentType, JSX } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { RootState } from '../../store/store';

import checkPermissions from './checkPermissions';

type AuthorizationCheckProps = {
  children: JSX.Element;
  Fallback?: ComponentType;
  permission: string;
};

const AuthorizationCheckNull = ({ children, permission, Fallback }: AuthorizationCheckProps): JSX.Element | null => {
  const userHasPermissions = useSelector((state: RootState) => checkPermissions(state, permission));
  if (userHasPermissions) {
    return children;
  } else {
    return Fallback ? <Fallback /> : null;
  }
};

AuthorizationCheckNull.propTypes = {
  children: PropTypes.node.isRequired,
  Fallback: PropTypes.node,
  permission: PropTypes.string.isRequired,
};

export default AuthorizationCheckNull;
