import { createSlice } from '@reduxjs/toolkit';

import { UserState } from '../../../../contracts/features/user.types';

import {
  resetUserReducer,
  setEmailReducer,
  setFirstNameReducer,
  setLastNameReducer,
  createUserPendingReducer,
  createUserFulfilledReducer,
  createUserRejectedReducer,
} from './user.reducer';
import { createUserAction } from './user.actions';

export const initialState: UserState = {
  firstName: '',
  lastName: '',
  email: '',
  message: '',
  isLoading: false,
  newUser: { _id: '', displayName: '' },
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    resetUser(state) {
      resetUserReducer(state, { type: '' });
    },
    setFirstName(state, action) {
      setFirstNameReducer(state, action);
    },
    setLastName(state, action) {
      setLastNameReducer(state, action);
    },
    setEmail(state, action) {
      setEmailReducer(state, action);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createUserAction.pending, createUserPendingReducer)
      .addCase(createUserAction.fulfilled, createUserFulfilledReducer)
      .addCase(createUserAction.rejected, createUserRejectedReducer);
  },
});

export const { setFirstName, setLastName, setEmail, resetUser } = userSlice.actions;

export default userSlice.reducer;
