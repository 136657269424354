import { createSlice } from '@reduxjs/toolkit';

import { NavigationConfig } from '../../../../contracts/features/navigation.types';

export const initialState: NavigationConfig = {
  isOpen: false,
};

const navigationSlice = createSlice({
  name: 'navigation',
  initialState,
  reducers: {
    toggleMenu(state) {
      state.isOpen = !state.isOpen;
    },
  },
});

export const { toggleMenu } = navigationSlice.actions;

export default navigationSlice.reducer;
