import { useContext, useEffect, createContext } from 'react';
import { BaseLocationHook } from 'wouter';
// eslint-disable-next-line import/extensions,import/no-unresolved
import { useBrowserLocation } from 'wouter/use-browser-location';

export const LockContext = createContext<{ lock: boolean; message?: string }>({ lock: false });

export const useRouteConfirmation = (message: string): void => {
  const ctx = useContext(LockContext);

  useEffect(() => {
    ctx.lock = true;
    ctx.message = message;

    return (): void => {
      ctx.lock = false;
      ctx.message = undefined;
    };
  }, []);
};

export const useLocationWithConfirmation = (): BaseLocationHook => {
  const [location, setLocation] = useBrowserLocation();
  const ctx = useContext(LockContext);

  return [
    location,
    (newLocation: string): void => {
      let performNavigation = true;
      if (ctx.lock) {
        // eslint-disable-next-line no-alert
        performNavigation = window.confirm(ctx.message || 'Are you sure?');
      }

      if (performNavigation) {
        setLocation(newLocation);
      }
    },
  ] as unknown as BaseLocationHook;
};
