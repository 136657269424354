import { createSlice } from '@reduxjs/toolkit';

import { MyCharacterConfig } from '../../../../contracts/features/my-characters.types';

import { getMyCharacters } from './my-characters.actions';
import { getMyCharactersFulfilledReducer } from './my-characters.reducer';

export const initialState: MyCharacterConfig = {
  characterList: [],
};

const myCharactersSlice = createSlice({
  name: 'myCharacters',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getMyCharacters.fulfilled, getMyCharactersFulfilledReducer);
  },
});

export default myCharactersSlice.reducer;
