import { CaseReducer, PayloadAction, SerializedError } from '@reduxjs/toolkit';
import _set from 'lodash/set';
import { toast } from 'react-toastify';

import { ExperienceStore } from '../../../../contracts/features/experience.types';
import { SelectedCharacter } from '../../../../contracts/experience.types';
import { characterConfig } from '../config/seaxe';
import { getToken, setToken } from '../../../services/localStorage';

export const resetExperienceReducer: CaseReducer<ExperienceStore> = (state): void => {
  state.message = '';
  state.selectedCharacters = [];
  setToken({ key: 'experience', data: [] });
};

export const addCharacterReducer: CaseReducer<ExperienceStore, PayloadAction<{ character: SelectedCharacter }>> = (
  state,
  action
): void => {
  const { character } = action.payload;

  const selectedCharacters = state.selectedCharacters;

  const formattedCharacter = { ...character, hours: characterConfig };

  state.selectedCharacters = [formattedCharacter, ...selectedCharacters];
  setToken({ key: 'experience', data: [formattedCharacter, ...selectedCharacters] });
};

type UpdateCharacterTypes = {
  id: string;
  field: string;
  value: string | boolean | number;
};

export const updateCharacterReducer: CaseReducer<ExperienceStore, PayloadAction<UpdateCharacterTypes>> = (
  state,
  action
): void => {
  const { id, field, value } = action.payload;

  const selectedCharacters = state.selectedCharacters;

  const updatedList = selectedCharacters.map((character) => {
    if (character.id === id) {
      const { hours } = character;

      const newHours = _set(hours, field, value);

      return { ...character, hours: newHours };
    }
    return character;
  });

  state.selectedCharacters = updatedList;
  setToken({ key: 'experience', data: updatedList });
};

export const removeCharacterReducer: CaseReducer<ExperienceStore, PayloadAction<string>> = (state, action): void => {
  const id = action.payload;

  const selectedCharacters = state.selectedCharacters;

  const newList = selectedCharacters.filter((character) => {
    return character.id !== id;
  });

  state.selectedCharacters = newList;
  setToken({ key: 'experience', data: newList });
};

export const updateMessageReducer: CaseReducer<ExperienceStore, PayloadAction<string>> = (state, action): void => {
  const message = action.payload;
  state.message = message;
};

export const submitFulfilledReducer: CaseReducer<
  ExperienceStore,
  PayloadAction<unknown, string, { arg: unknown; requestId: string; requestStatus: 'fulfilled' }, never>
> = (state) => {
  toast.success('Experience update successfully');
  state.message = '';
  state.selectedCharacters = [];

  setToken({ key: 'experience', data: [] });
};

export const submitRejectedReducer: CaseReducer<
  ExperienceStore,
  PayloadAction<unknown, string, unknown, SerializedError>
> = (_state, action) => {
  const { error } = action;
  console.error('submitRejectedReducer - error', error.message);
  toast.error('Failed to submit experience form');
};

export const restoreFromStoreReducer: CaseReducer<ExperienceStore> = (state): void => {
  const savedData = getToken('experience');

  if (Array.isArray(savedData)) {
    state.selectedCharacters = savedData;
  }
};
